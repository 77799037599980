import { rootApi } from "../../serviceRoot";
import {
  GetTextWidgetDataRequest,
  TTextWidgetDataResponse,
  TTextWidgetResponse,
  TTextWidgetSaveRequest,
  TTextWidgetSaveResponse,
} from "./Types";
import { WidgetDataRequest } from "../../Widget/Types";

export const textWidgetApiSlice = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    getTextWidget: builder.mutation<
      TTextWidgetResponse,
      WidgetDataRequest
    >({
      query: (payload) => ({
        url: "api/TextWidget/Get",
        method: "POST",
        body: payload,
      }),
    }),
    getTextWidgetData: builder.query<
      TTextWidgetDataResponse,
      GetTextWidgetDataRequest
    >({
      query: (params) => ({
        url: "api/Widget/GetData",
        method: "GET",
        params: {
          widgetId: params.widgetId,
          dashboardId: params.dashboardId,
        },
      }),
      providesTags: (result, error, arg) =>
        result
          ? [{ id: result.widgetData.id, type: "TextWidget" }]
          : ["TextWidget"],
    }),
    createTextWidget: builder.mutation<any, TTextWidgetSaveRequest>({
      query: (payload) => ({
        url: "api/TextWidget/Create",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["Widgets"],
    }),
    updateTextWidget: builder.mutation<
      TTextWidgetSaveResponse,
      TTextWidgetSaveRequest
    >({
      query: (payload) => ({
        url: "api/TextWidget/Update",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: (result, error, arg) => {
        return result
          ? [{ type: "TextWidget", id: result.id }, "Widgets"]
          : ["TextWidget", "Widgets"];
      },
    }),
  }),
});

export const {
  useGetTextWidgetDataQuery,
  useCreateTextWidgetMutation,
  useUpdateTextWidgetMutation,
  useGetTextWidgetMutation,
  useLazyGetTextWidgetDataQuery,
} = textWidgetApiSlice;
