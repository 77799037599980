import { useState } from "react";
import Box from "@mui/material/Box";
import { Stack } from "@mui/material";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router";
import { useAddUserGroupMutation } from "../../features/serviceSlices/serviceHooks";
import { SaveButton } from "../../components/common";
import { useIntl } from "react-intl";
import { DbaTextField } from "../../DbaComponents";
import { DbaConfirmDialog } from "../../DbaComponents/DbaConfirmDialog";

export const UserGroup = () => {
  const intl = useIntl();
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const navigate = useNavigate();
  const [addUserGroup, response] = useAddUserGroupMutation();
  const [error, setError] = useState(false);
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] =
    useState<boolean>(false);

  const onSubmitFormHandler = () => {
    if (response.status === "pending" || response.status === "fulfilled")
      return;
    if (!name.trim()) {
      setError(true);
    } else {
      setError(false);
      addUserGroup({
        payload: { name: name.trim(), description: description.trim() },
      });
    }
  };

  const onConfirmCancelClick = () => {
    navigate(-1);
  };

  const confirmCancelModal = (
    <DbaConfirmDialog
      isOpen={isConfirmDialogOpen}
      title="cancelConfirmTitle"
      message="cancelConfirmMessage"
      onAccept={onConfirmCancelClick}
      onDecline={() => setIsConfirmDialogOpen(false)}
    />
  );

  return (
    <Box component="form" onSubmit={onSubmitFormHandler}>
      <Stack direction="row" spacing={2} sx={{ mb: "1rem" }}>
        <Button
          onClick={() => setIsConfirmDialogOpen(true)}
          sx={{ width: "fit-content" }}
          color="error"
          variant="contained"
        >
          {intl.messages["cancel"]}
        </Button>
        <SaveButton
          redirectUrl="/usergroups"
          onClick={onSubmitFormHandler}
          status={response.status}
        />
      </Stack>
      <Stack direction="column" spacing={2}>
        <DbaTextField
          required
          error={error && !name.trim()}
          value={name}
          setValue={setName}
          size="medium"
          label="label"
          helperText="fieldIsEmptyError"
        />
        <DbaTextField
          value={description}
          setValue={setDescription}
          size="medium"
          label="description"
        />
      </Stack>
      {confirmCancelModal}
    </Box>
  );
};
