import { useContext, useEffect } from "react";
import { DbaDatepicker } from "..";
import styled from "@emotion/styled";
import { DbaDataRangePickerProps } from "./Types";
import { TimeField } from '@mui/x-date-pickers/TimeField';
import colors from "../../Variables.module.scss";
import { ThemeContext } from "../../utils/ThemeContext";

const CSSDbaDataRangePickerContainer = styled.div<{ mobileView?: boolean }>`
  display: flex;
  flex-direction: ${(props) => (props.mobileView ? "column" : "row")};
  gap: 1rem;
  width: fit-content;
`;

export const DbaDataRangePicker = ({
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  mobileView,
  startDateLabel,
  endDateLabel,
  disabled,
  withTimeField
}: DbaDataRangePickerProps) => {
  useEffect(() => {
    if (endDate && endDate < startDate) {
      setEndDate(startDate);
    }
  }, [endDate, setEndDate, startDate]);

  const { darkMode } = useContext(ThemeContext);

  const fieldsetStyle = darkMode ? colors.purple500 : colors.darkBlue;
  const datePickerWidth = mobileView ? "225px" : "160px";
  return (
    <CSSDbaDataRangePickerContainer mobileView={mobileView}>
      <DbaDatepicker
        width={datePickerWidth}
        dateFormat="dd.MM.yyyy"
        selected={startDate}
        label={startDateLabel ? startDateLabel : "chooseStartDate"}
        setDate={(date: any) => setStartDate(date)}
        date={startDate}
        disabled={disabled}
      />
      {!withTimeField ? (
        ""
      ) : (
        <TimeField
          value={startDate}
          onChange={(newValue) => {newValue && setStartDate(newValue)}}
          size="small"
          format="HH:mm"
          sx={{
            width: 70,
            "& fieldset": {
              borderColor: fieldsetStyle,
            }
          }}
        />
      )}
      <DbaDatepicker
        width={datePickerWidth}
        dateFormat="dd.MM.yyyy"
        selected={endDate}
        label={endDateLabel ? endDateLabel : "chooseEndDate"}
        setDate={(date: any) => setEndDate(date)}
        date={endDate}
        disabled={disabled}
      />
      {!withTimeField ? (
        ""
      ) : (
        <TimeField
          value={endDate}
          onChange={(newValue) => {
            newValue && setEndDate(newValue)
          }}
          size="small"
          format="HH:mm"
          sx={{
            width: 70,
            "& fieldset": {
              borderColor: fieldsetStyle,
            }
          }}
        />
      )}
      
    </CSSDbaDataRangePickerContainer>
  );
};
