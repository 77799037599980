import { useContext, useState } from "react";
import {
  ImageWidgetConstructorProps,
  WidgetElementTypes,
} from "./ImageWidgetConstructor.types";
import { WidgetConstructorToolbar } from "../WidgetConstructorToolbar";
import { DbaErrorBoundary } from "../../../../DbaComponents";
import {
  CSSConstructorContainer,
  CSSEditableWidgetContainer,
} from "./ImageWidgetConstructor.styles";
import {
  ImageWidget,
  WidgetContainer,
} from "../../../../components/Widgets/common";
import { ThemeContext } from "../../../../utils/ThemeContext";

export const ImageWidgetConstructor = ({
  settings,
  setSettings,
  widgetData,
}: ImageWidgetConstructorProps) => {
  const { darkMode } = useContext(ThemeContext);
  const [selectedElement, setSelectedElement] =
    useState<WidgetElementTypes | null>("body");

  return (
    <CSSConstructorContainer>
      <WidgetConstructorToolbar
        widgetType="Image"
        settings={settings}
        setSettings={setSettings}
        selectedElement={selectedElement}
        setSelectedElement={setSelectedElement}
      />
      <CSSEditableWidgetContainer>
        <DbaErrorBoundary>
          <WidgetContainer
            type="Image"
            darkMode={darkMode}
            body={settings.widget.body}
          >
            <ImageWidget
              data={{
                id: widgetData.id,
                fileId: widgetData.fileId,
                description: widgetData.description,
                label: widgetData.label,
                name: widgetData.name,
                widgetSettings: {
                  settings,
                },
                widgetType: "Image",
                widgetLinks: [],
                widgetReports: [],
              }}
            />
          </WidgetContainer>
        </DbaErrorBoundary>
      </CSSEditableWidgetContainer>
    </CSSConstructorContainer>
  );
};
