import { rootApi } from "../serviceRoot";

import {
  AllUsersResponse,
  AssignRoleRequest,
  AuthenticateRequest,
  AuthenticateResponse,
  DeleteUserRequest,
  ForgetPasswordRequest,
  RecoveryPasswordRequest,
  RefreshTokenRequest,
  RegisterRequest,
  RegisterResponse,
  ResetPasswordRequest,
  RevokeTokenRequest,
  UpdateUserRequest,
  UserWithGroupsResponse
} from "./Types";

export const authenticationApiSlice = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllUsers: builder.query<AllUsersResponse, boolean>({
      query: (payload) => `api/user/getall?IsShowDeleted=${payload}`,
      providesTags: ["Users"],
    }),

    getUser: builder.query<UserWithGroupsResponse, number>({
      query: (id) => ({
        url: `api/user/Get?Id=${id}`,
      }),
      providesTags: ["Users"],
    }),

    registerUser: builder.mutation<RegisterResponse, RegisterRequest>({
      query: (payload) => ({
        url: "api/User/Register",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["Users", "GroupUsers"],
    }),

    authenticate: builder.mutation<AuthenticateResponse, AuthenticateRequest>({
      query: (payload) => ({
        url: "api/User/Authenticate",
        method: "POST",
        body: payload,
        credentials: "include",
      }),
      invalidatesTags: [
        "Users",
        "GroupUsers",
        "Dashboards",
        "dashboardWidgets",
        "WidgetsInDashboards",
      ],
    }),

    refreshToken: builder.mutation<AuthenticateResponse, RefreshTokenRequest>({
      query: (payload) => ({
        url: "api/User/RefreshToken",
        method: "POST",
        body: payload,
      }),
    }),

    revokeToken: builder.mutation<boolean, RevokeTokenRequest>({
      query: (payload) => ({
        url: "api/User/RevokeToken",
        method: "POST",
        body: payload,
      }),
    }),

    assignRoleToUser: builder.mutation<boolean, AssignRoleRequest>({
      query: (payload) => ({
        url: "api/User/AssignRole",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["Users"],
    }),

    forgetPassword: builder.mutation<string, ForgetPasswordRequest>({
      query: (payload) => ({
        url: "api/User/ForgetPassword",
        method: "POST",
        body: payload,
      }),
    }),

    recoveryPassword: builder.mutation<string, RecoveryPasswordRequest>({
      query: (payload) => ({
        url: "api/User/RecoveryPassword",
        method: "POST",
        body: payload,
      }),
    }),

    resetPassword: builder.mutation<string, ResetPasswordRequest>({
      query: (payload) => ({
        url: "api/User/ResetPassword",
        method: "POST",
        body: payload,
      }),
    }),

    deleteUser: builder.mutation<boolean, DeleteUserRequest>({
      query: (payload) => ({
        url: "api/User/Delete",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["Users"],
    }),

    updateUser: builder.mutation<boolean, UpdateUserRequest>({
      query: (payload) => ({
        url: "api/User/Update",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["Users"],
    }),
  }),
});

export const {
  useRegisterUserMutation,
  useGetUserQuery,
  useLazyGetUserQuery,
  useAuthenticateMutation,
  useRefreshTokenMutation,
  useRevokeTokenMutation,
  useAssignRoleToUserMutation,
  useForgetPasswordMutation,
  useRecoveryPasswordMutation,
  useResetPasswordMutation,
  useDeleteUserMutation,
  useUpdateUserMutation,
  useLazyGetAllUsersQuery,
  useGetAllUsersQuery,
} = authenticationApiSlice;
