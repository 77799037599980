import {
  EReferenceHeaderDateFormats,
  TimePeriods,
  TISODateString,
  TTableHeaderColumn,
  TYYYYDDMMHHmmString,
} from "../../features/serviceSlices/SharedTypes";
import { utcToZonedTime, zonedTimeToUtc, formatInTimeZone } from "date-fns-tz";
import set from "date-fns/set";
import { TFilterData } from "../../DbaComponents/DbaTable/Types";

export const convertSecondsToHoursMinutes = (seconds: number): string => {
  const minutes = parseInt(String(seconds / 60));
  let h: number | string = Math.floor(minutes / 60);
  let m: number | string = minutes % 60;
  h = h < 10 ? "0" + h : h;
  m = m < 10 ? "0" + m : m;
  return h + ":" + m;
};

export const convertDateToISOUsingExternalTimeZone = (
  date: Date,
  timezone: string
): TISODateString => {
  return zonedTimeToUtc(date, timezone).toISOString() as TISODateString;
};

export const convertISODateWithTimeZoneToLocalStringWithTargetTimeZone = ({
  date,
  dateTZ,
  targetTZ,
  dateFormatType,
}: {
  date: TISODateString;
  dateTZ: string;
  targetTZ: string;
  dateFormatType: EReferenceHeaderDateFormats;
}): string => {
  const isoDate = new Date(
    utcToZonedTime(zonedTimeToUtc(date, dateTZ).toISOString(), targetTZ)
      .toISOString()
      .replace("Z", "")
  );

  const formatHandlers = {
    [EReferenceHeaderDateFormats.DATE_AND_TIME]: () => {
      return isoDate.toLocaleString();
    },
    [EReferenceHeaderDateFormats.ONLY_DATE]: () => {
      return isoDate.toLocaleDateString();
    },
  };

  return formatHandlers[dateFormatType]();
};

export const restoreISODateUsingTimeZoneAndConvertToISODateWithTargetTZ = ({
  date,
  dateTZ,
  targetTZ,
}: {
  date: TISODateString;
  dateTZ: string;
  targetTZ: string;
}): TISODateString => {
  return convertDateToISOUsingExternalTimeZone(
    utcToZonedTime(date, dateTZ),
    targetTZ
  );
};

export const convertISODateToYYYMMddWithTargetTimeZone = (
  date: TISODateString,
  timezone: string
): TYYYYDDMMHHmmString => {
  return formatInTimeZone(date, timezone, "yyyy/MM/dd HH:mm") as TYYYYDDMMHHmmString;
};

export const convertISODatesInCollectionToLocalStringByTimezonesShift = <T>({
  collection,
  headers,
  originalTZ,
  targetTZ,
}: {
  collection: T[];
  headers: TTableHeaderColumn[];
  originalTZ: string;
  targetTZ: string;
}): T[] => {
  if (!collection.length || !headers.length) {
    return [];
  }
  const dateHeaders = headers
    .filter((header) =>
      Object.values(EReferenceHeaderDateFormats).includes(
        header.type as EReferenceHeaderDateFormats
      )
    )
    .map((header) => ({ field: header.field, type: header.type }));
  return collection.map((item: any) => {
    return dateHeaders.reduce(
      (itemUpdated, header) => {
        let resultValue;
        try {
          resultValue =
            convertISODateWithTimeZoneToLocalStringWithTargetTimeZone({
              date: itemUpdated[header.field],
              dateTZ: originalTZ,
              targetTZ,
              dateFormatType: header.type as EReferenceHeaderDateFormats,
            });
        } catch (error) {
          resultValue = itemUpdated[header.field];
        }
        itemUpdated[header.field] = resultValue;
        return itemUpdated;
      },
      { ...item }
    );
  });
};

export const createNewDateOnPeriodStart = (
  period: Omit<TimePeriods, string>,
  anyDateFromPeriod?: TISODateString
): Date | undefined => {
  const dateToHandle = anyDateFromPeriod
    ? new Date(anyDateFromPeriod)
    : new Date();
  if (period === "Day") {
    return set(dateToHandle, {
      hours: 0,
      minutes: 0,
      seconds: 0,
      milliseconds: 0,
    });
  } else if (["Month", "Custom"].includes(period as TimePeriods)) {
    return set(dateToHandle, {
      date: 1,
      hours: 0,
      minutes: 0,
      seconds: 0,
      milliseconds: 0,
    });
  } else if (period === "Year") {
    return set(dateToHandle, {
      month: 0,
      date: 1,
      hours: 0,
      minutes: 0,
      seconds: 0,
      milliseconds: 0,
    });
  }
};

export const createNewDateOnPeriodEnd = (
  period: Omit<TimePeriods, "Custom" | string>,
  anyDateFromPeriod?: TISODateString
): Date | undefined => {
  const dateToHandle = anyDateFromPeriod
    ? new Date(anyDateFromPeriod)
    : new Date();
  if (period === "Day") {
    return set(dateToHandle, {
      hours: 23,
      minutes: 59,
      seconds: 59,
      milliseconds: 999,
    });
  } else if (period === "Month") {
    return set(dateToHandle, {
      date: getDaysAmountInMonth(dateToHandle),
      hours: 23,
      minutes: 59,
      seconds: 59,
      milliseconds: 999,
    });
  } else if (period === "Year") {
    return set(dateToHandle, {
      month: 11,
      date: 31,
      hours: 23,
      minutes: 59,
      seconds: 59,
      milliseconds: 999,
    });
  } else if (period === "Custom") {
    return set(dateToHandle, {
      month: dateToHandle.getMonth() + 1,
      date: 1,
      hours: 0,
      minutes: 0,
      seconds: 0,
      milliseconds: 0,
    });
  }
};

export const getDaysAmountInMonth = (date: Date) => {
  return new Date(date.getUTCFullYear(), date.getMonth() + 1, 0).getDate();
};

export const convertFilterTableDatesToISOUsingExternalTimeZone = (
  data: TFilterData[],
  timezone: string
) => {
  const newArr = data.map((x) => {
    if (x.fieldType === "Date") {
      return {
        ...x,
        value: convertDateToISOUsingExternalTimeZone(x?.value, timezone),
      };
    }
    return x;
  });
  return newArr;
};
