import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import { DbaIconButton } from "../DbaIconButton";
import { useIntl } from "react-intl";
import CloseIcon from "@mui/icons-material/Close";
import DialogContent from "@mui/material/DialogContent";
import {
  Button,
  DialogActions,
  DialogContentText,
  Typography,
} from "@mui/material";

type DbaConfirmDialogProps = {
  title: string;
  message: string;
  isOpen: boolean;
  loading?: boolean;
  centered?: boolean;
  onAccept?: () => void;
  onDecline?: () => void;
};

export const DbaConfirmDialog = ({
  title,
  message,
  isOpen,
  loading,
  onAccept,
  onDecline,
}: DbaConfirmDialogProps) => {
  const intl = useIntl();

  return (
    <Dialog
      PaperProps={{
        sx: {
          minWidth: 350,
        },
      }}
      open={isOpen}
      maxWidth="xl"
      fullWidth={false}
      onClose={onDecline}
      scroll="paper"
    >
      <DialogTitle sx={{ display: "flex", gap: "16px", minHeight: "64px" }}>
        <Typography style={{ marginRight: "24px" }} variant="body1">
          {intl.messages[title] ?? title}
        </Typography>
        <DbaIconButton
          sx={{
            position: "absolute",
            top: 8,
            right: 8,
          }}
          icon={<CloseIcon />}
          onClick={onDecline}
        />
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {intl.messages[message] ?? message}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onDecline ? onDecline : undefined} disabled={loading}>
          {intl.messages["no"] as string}
        </Button>
        <Button
          variant={"contained"}
          onClick={onAccept ? onAccept : undefined}
          disabled={loading}
        >
          {intl.messages["yes"] as string}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
