import { useEffect } from "react";
import { useAppSelector } from "../../../../utils/reduxHooks";
import { LoadingScreen, ErrorMessage } from "../../../common";
import type { WidgetProps } from "../Widgets.types";
import { CSSWrapper } from "../../common/WidgetLayout/WidgetLayout.styles";
import { AuthGuard } from "../../../Guards/AuthGuard/AuthGuard";
import { WidgetMenu } from "../../common/WidgetMenu";
import { FetchBaseQueryErrorType } from "../../../../features/serviceSlices/SharedTypes";
import { useGetImageWidgetDataQuery } from "../../../../features/serviceSlices/serviceHooks";
import { ImageWidget } from "../../common";

export const Image = ({ id, setBody, setResizeHandlerColor }: WidgetProps) => {
  const { role, editMode, authorizationInfo } = useAppSelector(
    (state) => state.app
  );
  const imageWidgetData = useGetImageWidgetDataQuery({
    widgetId: id,
    dashboardId: role.id,
  });

  useEffect(() => {
    if (
      imageWidgetData.data &&
      imageWidgetData.data?.widgetData.widgetSettings?.settings?.widget?.body
    ) {
      setBody(
        imageWidgetData.data.widgetData.widgetSettings.settings.widget.body
      );
    }
  }, [imageWidgetData.data, setBody]);

  useEffect(() => {
    if (
      imageWidgetData.data &&
      imageWidgetData.data?.widgetData.widgetSettings?.settings?.widget
        ?.fontColor
    ) {
      setResizeHandlerColor(
        imageWidgetData.data.widgetData.widgetSettings.settings.widget
          ?.fontColor
      );
    }
  }, [imageWidgetData.data, setResizeHandlerColor]);

  const getErrorMessage = () => {
    return (
      <CSSWrapper>
        <ErrorMessage
          error={imageWidgetData.error as FetchBaseQueryErrorType}
          isShowTooltip={!!authorizationInfo?.isAdmin}
        />
        <AuthGuard>
          <WidgetMenu
            dashboardId={role.id}
            widgetID={id}
            type={"Video"}
            editMode={editMode}
            isError={true}
          />
        </AuthGuard>
      </CSSWrapper>
    );
  };

  return imageWidgetData.isLoading ? (
    <LoadingScreen />
  ) : imageWidgetData.isSuccess ? (
    <ImageWidget data={imageWidgetData.data.widgetData} />
  ) : (
    getErrorMessage()
  );
};
