import styled from "@emotion/styled";
import colors from "../../Variables.module.scss";
import { WidgetBodySettings } from "../../features/serviceSlices/WidgetSettings/Types";
import { hexToRgb } from "../../utils/helpers/functions";

const CSSWidgetContainer = styled.div`
  label: FullScreenWidgetContainer;
  padding: 8px 16px 16px 16px;
  height: 100%;
`;

type WidgetContainerProps = {
  body: WidgetBodySettings;
  type?: "Diagram";
};

const CSSFullScreenWidgetContainer = styled.div<WidgetContainerProps>`
  border-radius: ${({ body }) =>
    body?.borderRadius || body?.borderRadius === 0
      ? `${body.borderRadius}px`
      : "0.5rem"};
  padding: 1.5rem;
  background: ${({ body, theme }: any) => {
    const rgbColor = hexToRgb(
      body?.backgroundColor
        ? body.backgroundColor
        : theme.palette.mode !== "light"
        ? colors.darkBlue
        : colors.white
    )!;
    return body?.visible && rgbColor
      ? `rgba(${rgbColor.r}, ${rgbColor.g}, ${rgbColor.b}, ${
          body?.backgroundOpacity ? body.backgroundOpacity : 1
        })`
      : "";
  }};
  box-shadow: ${({ body, theme }: any) =>
    theme.palette.mode !== "light" || !body?.visible
      ? "none"
      : colors.bsCardLight};
  backdrop-filter: blur(${({ body }) => (body?.blur ? body.blur : 0)}px);
  color: ${(props: any) =>
    props.theme.palette.mode !== "light" ? colors.white : colors.darkBlue};
  width: 100%;
  height: 100%;

  &:hover button {
    opacity: 1;
  }
`;

const CSSFullScreenMapContainer = styled.div`
  label: FullScreenMapContainer;
  width: 100%;
  height: 100%;
  padding: 8px 16px 0 16px;
`;

const CSSFullScreenImageContainer = styled.div`
  width: 100%;
  height: 100%;
  padding: 24px;
`;

export {
  CSSFullScreenWidgetContainer,
  CSSFullScreenMapContainer,
  CSSWidgetContainer,
  CSSFullScreenImageContainer,
};
