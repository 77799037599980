import { DbaToggleButtonGroupProps, WidgetElementTypes } from "./Types";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import colors from "../../../../../Variables.module.scss";
import { useIntl } from "react-intl";
import { ValueIcon } from "./ValueIcon";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import HistoryIcon from "@mui/icons-material/History";
import SpeedIcon from "@mui/icons-material/Speed";
import FormatColorFillIcon from "@mui/icons-material/FormatColorFill";
import LabelIcon from "@mui/icons-material/Label";
import styled from "@emotion/styled";
import { useContext } from "react";
import { ThemeContext } from "../../../../../utils/ThemeContext";
import StyleIcon from "@mui/icons-material/Style";
import AnimationIcon from "@mui/icons-material/Animation";
import LegendToggleIcon from "@mui/icons-material/LegendToggle";
import PieChartIcon from "@mui/icons-material/PieChart";
import SettingsIcon from "@mui/icons-material/Settings";
import InfoIcon from "@mui/icons-material/Info";
import TextFieldsIcon from "@mui/icons-material/TextFields";
import LineAxisIcon from "@mui/icons-material/LineAxis";
import LocationSearchingIcon from "@mui/icons-material/LocationSearching";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import DataThresholdingIcon from "@mui/icons-material/DataThresholding";

const CSSToggleButtonContent = styled.div`
  display: flex;
  gap: 0.25rem;
  align-items: center;
`;

export const DbaToggleButtonGroup = ({
  selectedElement,
  setSelectedElement,
  widgetType,
  diagramType,
}: DbaToggleButtonGroupProps) => {
  const { darkMode } = useContext(ThemeContext);
  const intl = useIntl();

  const ToggleButtonStyle = {
    border: `1px solid ${colors.purple500}`,
    color: darkMode ? colors.light : colors.darkBlue,
  };

  const ToggleButtonGroupStyle = {
    height: "fit-content",
    width: "fit-content",
    background: darkMode ? colors.darkBlue : "",
  };

  const onChangeHandler = (
    event: React.MouseEvent<HTMLElement>,
    value: WidgetElementTypes
  ) => {
    setSelectedElement(value);
  };

  if (widgetType === "Table" || widgetType === "Map") {
    return (
      <ToggleButtonGroup
        exclusive
        value={selectedElement}
        onChange={onChangeHandler}
        size="small"
        sx={ToggleButtonGroupStyle}
      >
        <ToggleButton value="template" sx={ToggleButtonStyle}>
          <CSSToggleButtonContent>
            <LibraryBooksIcon fontSize="small" />
            <p>{intl.messages["template"]}</p>
          </CSSToggleButtonContent>
        </ToggleButton>
        <ToggleButton value="body" sx={ToggleButtonStyle}>
          <CSSToggleButtonContent>
            <FormatColorFillIcon fontSize="small" />
            <p>{intl.messages["background"]}</p>
          </CSSToggleButtonContent>
        </ToggleButton>
        <ToggleButton sx={ToggleButtonStyle} value="header">
          <CSSToggleButtonContent>
            <LabelIcon fontSize="small" />
            <p>{intl.messages["label"]}</p>
          </CSSToggleButtonContent>
        </ToggleButton>
      </ToggleButtonGroup>
    );
  }

  if (widgetType === "Filter") {
    return (
      <ToggleButtonGroup
        exclusive
        value={selectedElement}
        onChange={onChangeHandler}
        size="small"
        sx={ToggleButtonGroupStyle}
      >
        <ToggleButton value="template" sx={ToggleButtonStyle}>
          <CSSToggleButtonContent>
            <LibraryBooksIcon fontSize="small" />
            <p>{intl.messages["template"]}</p>
          </CSSToggleButtonContent>
        </ToggleButton>
        <ToggleButton value="body" sx={ToggleButtonStyle}>
          <CSSToggleButtonContent>
            <FormatColorFillIcon fontSize="small" />
            <p>{intl.messages["background"]}</p>
          </CSSToggleButtonContent>
        </ToggleButton>
      </ToggleButtonGroup>
    );
  }

  if (widgetType === "Text" || widgetType === "Image") {
    return (
      <ToggleButtonGroup
        exclusive
        value={selectedElement}
        onChange={onChangeHandler}
        size="small"
        sx={ToggleButtonGroupStyle}
      >
        <ToggleButton value="template" sx={ToggleButtonStyle}>
          <CSSToggleButtonContent>
            <LibraryBooksIcon fontSize="small" />
            <p>{intl.messages["template"]}</p>
          </CSSToggleButtonContent>
        </ToggleButton>
        <ToggleButton value="body" sx={ToggleButtonStyle}>
          <CSSToggleButtonContent>
            <FormatColorFillIcon fontSize="small" />
            <p>{intl.messages["background"]}</p>
          </CSSToggleButtonContent>
        </ToggleButton>
        <ToggleButton sx={ToggleButtonStyle} value="header">
          <CSSToggleButtonContent>
            <LabelIcon fontSize="small" />
            <p>{intl.messages["label"]}</p>
          </CSSToggleButtonContent>
        </ToggleButton>
      </ToggleButtonGroup>
    );
  }

  return widgetType === "Default" ? (
    <ToggleButtonGroup
      exclusive
      value={selectedElement}
      onChange={onChangeHandler}
      size="small"
      sx={ToggleButtonGroupStyle}
    >
      <ToggleButton value="template" sx={ToggleButtonStyle}>
        <CSSToggleButtonContent>
          <LibraryBooksIcon fontSize="small" />
          <p>{intl.messages["template"]}</p>
        </CSSToggleButtonContent>
      </ToggleButton>
      <ToggleButton value="body" sx={ToggleButtonStyle}>
        <CSSToggleButtonContent>
          <FormatColorFillIcon fontSize="small" />
          <p>{intl.messages["background"]}</p>
        </CSSToggleButtonContent>
      </ToggleButton>
      <ToggleButton sx={ToggleButtonStyle} value="header">
        <CSSToggleButtonContent>
          <LabelIcon fontSize="small" />
          <p>{intl.messages["label"]}</p>
        </CSSToggleButtonContent>
      </ToggleButton>
      <ToggleButton value="value" sx={ToggleButtonStyle}>
        <CSSToggleButtonContent>
          <ValueIcon fontSize="small" />
          <p>{intl.messages["value"]}</p>
        </CSSToggleButtonContent>
      </ToggleButton>
      <ToggleButton sx={ToggleButtonStyle} value="measure">
        <CSSToggleButtonContent>
          <SpeedIcon fontSize="small" />
          <p>{intl.messages["measuresShorthand"]}</p>
        </CSSToggleButtonContent>
      </ToggleButton>
      <ToggleButton value="difference" sx={ToggleButtonStyle}>
        <CSSToggleButtonContent>
          <TrendingUpIcon fontSize="small" />
          <p>{intl.messages["difference"]}</p>
        </CSSToggleButtonContent>
      </ToggleButton>
      <ToggleButton sx={ToggleButtonStyle} value="previousValue">
        <CSSToggleButtonContent>
          <HistoryIcon fontSize="small" />
          <p>{intl.messages["previousValue"]}</p>
        </CSSToggleButtonContent>
      </ToggleButton>

      <ToggleButton sx={ToggleButtonStyle} value="thresholds">
        <CSSToggleButtonContent>
          <DataThresholdingIcon fontSize="small" />
          <p>{intl.messages["thresholds"]}</p>
        </CSSToggleButtonContent>
      </ToggleButton>
    </ToggleButtonGroup>
  ) : (
    <ToggleButtonGroup
      exclusive
      value={selectedElement}
      onChange={onChangeHandler}
      size="small"
      sx={ToggleButtonGroupStyle}
    >
      <ToggleButton value="template" sx={ToggleButtonStyle}>
        <CSSToggleButtonContent>
          <LibraryBooksIcon fontSize="small" />
          <p>{intl.messages["template"]}</p>
        </CSSToggleButtonContent>
      </ToggleButton>
      <ToggleButton value="body" sx={ToggleButtonStyle}>
        <CSSToggleButtonContent>
          <FormatColorFillIcon fontSize="small" />
          <p>{intl.messages["background"]}</p>
        </CSSToggleButtonContent>
      </ToggleButton>
      <ToggleButton sx={ToggleButtonStyle} value="header">
        <CSSToggleButtonContent>
          <LabelIcon fontSize="small" />
          <p>{intl.messages["label"]}</p>
        </CSSToggleButtonContent>
      </ToggleButton>
      <ToggleButton sx={ToggleButtonStyle} value="diagramType">
        <CSSToggleButtonContent>
          <PieChartIcon fontSize="small" />
          <p>{intl.messages["diagramType"]}</p>
        </CSSToggleButtonContent>
      </ToggleButton>
      <ToggleButton sx={ToggleButtonStyle} value="baseSettings">
        <CSSToggleButtonContent>
          <SettingsIcon fontSize="small" />
          <p>{intl.messages["baseSettings"]}</p>
        </CSSToggleButtonContent>
      </ToggleButton>
      <ToggleButton sx={ToggleButtonStyle} value="styles">
        <CSSToggleButtonContent>
          <StyleIcon fontSize="small" />
          <p>{intl.messages["styles"]}</p>
        </CSSToggleButtonContent>
      </ToggleButton>

      {diagramType !== "Line" && diagramType !== "Radar" && (
        <ToggleButton sx={ToggleButtonStyle} value="labels">
          <CSSToggleButtonContent>
            <TextFieldsIcon fontSize="small" />
            <p>{intl.messages["arcLabels"]}</p>
          </CSSToggleButtonContent>
        </ToggleButton>
      )}

      {(diagramType === "Line" || diagramType === "Radar") && (
        <ToggleButton sx={ToggleButtonStyle} value="points">
          <CSSToggleButtonContent>
            <LocationSearchingIcon fontSize="small" />
            <p>{intl.messages["points"]}</p>
          </CSSToggleButtonContent>
        </ToggleButton>
      )}

      {diagramType === "Pie" && (
        <ToggleButton sx={ToggleButtonStyle} value="arcLinkLabels">
          <CSSToggleButtonContent>
            <InfoIcon fontSize="small" />
            <p>{intl.messages["arcLinkLabels"]}</p>
          </CSSToggleButtonContent>
        </ToggleButton>
      )}
      {(diagramType === "Bar" ||
        diagramType === "Line" ||
        diagramType === "Radar" ||
        diagramType === "Funnel" ||
        diagramType === "HeatMap" ||
        diagramType === "RadialBar" ||
        diagramType === "AreaBump") && (
        <ToggleButton sx={ToggleButtonStyle} value="axes">
          <CSSToggleButtonContent>
            <LineAxisIcon fontSize="small" />
            <p>{intl.messages["axes"]}</p>
          </CSSToggleButtonContent>
        </ToggleButton>
      )}
      <ToggleButton sx={ToggleButtonStyle} value="interactivity">
        <CSSToggleButtonContent>
          <AnimationIcon fontSize="small" />
          <p>{intl.messages["interactivity"]}</p>
        </CSSToggleButtonContent>
      </ToggleButton>
      {diagramType !== "HeatMap" &&
        diagramType !== "Calendar" &&
        diagramType !== "AreaBump" &&
        diagramType !== "Funnel" && (
          <ToggleButton sx={ToggleButtonStyle} value="legends">
            <CSSToggleButtonContent>
              <LegendToggleIcon fontSize="small" />
              <p>{intl.messages["legends"]}</p>
            </CSSToggleButtonContent>
          </ToggleButton>
        )}
      <ToggleButton sx={ToggleButtonStyle} value="thresholds">
        <CSSToggleButtonContent>
          <DataThresholdingIcon fontSize="small" />
          <p>{intl.messages["thresholds"]}</p>
        </CSSToggleButtonContent>
      </ToggleButton>
    </ToggleButtonGroup>
  );
};
