import { useState } from "react";
import { CSSExpressionContainer } from "../QueryConstructor/QueryConstructor.styles";
import { DbaDataFetchSelect, DbaTextField } from "../../DbaComponents";
import { QueryConstructorModal } from "../QueryConstructor/components/QueryConstructorModal";
import { FilterType, FilterVariableTypeType } from "./FiltersConstructor.types";
import { useIntl } from "react-intl";

export const Filter = ({
  data,
  onSaveChanges,
  tableName,
  deleteHandler,
  dataSourceId,
  isManualTextControl,
}: {
  data: FilterType;
  onSaveChanges: (arg: FilterType, initialState: FilterType) => void;
  tableName?: string;
  deleteHandler: (arg: FilterType) => void;
  dataSourceId: string;
  isManualTextControl?: boolean;
}) => {
  const intl = useIntl();

  const [field, setField] = useState<any>(data.field);
  const [conditionalOperator, setConditionalOperator] = useState<any>(
    data.condition
  );
  const [filterVariableType, setFilterVariableType] =
    useState<FilterVariableTypeType>({ id: data.type, name: "" });
  const [variable, setVariable] = useState(data.variable);
  const [open, setOpen] = useState(data.field === "");
  const handleOpen = () => setOpen(true);
  const [error, setError] = useState(false);

  const onSaveExpressionHandler = () => {
    const tableFieldToSave = isManualTextControl ? field : field?.name;
    if (
      tableFieldToSave &&
      conditionalOperator?.id &&
      filterVariableType?.id &&
      variable.trim().length > 0
    ) {
      onSaveChanges(
        {
          field: tableFieldToSave,
          condition: conditionalOperator.id ? conditionalOperator.id : null,
          type: filterVariableType.id ? filterVariableType.id : null,
          variable,
        },
        data
      );
      setOpen(false);
    } else {
      setError(true);
    }
  };

  const onDeleteOrderHandler = () => {
    deleteHandler(data);
    setOpen(false);
  };

  return (
    <>
      <CSSExpressionContainer onClick={handleOpen}>
        <span style={{ fontWeight: 800 }}>{field?.name ?? data.field}</span>
        <span>{data.condition}</span>
        <span>{data.variable}</span>
      </CSSExpressionContainer>
      <QueryConstructorModal
        open={open}
        title={intl.messages["filters"] as string}
        saveHandler={onSaveExpressionHandler}
        deleteHandler={onDeleteOrderHandler}
      >
        {isManualTextControl ? (
          <DbaTextField
            required
            error={error && !field}
            size="small"
            label="tableField"
            value={field}
            setValue={setField}
          />
        ) : (
          <DbaDataFetchSelect
            required
            error={error && !field}
            disableClearable
            size="small"
            url={`api/Query/GetFields?DataSourceID=${dataSourceId}&TableName=${tableName}`}
            label="tableField"
            selectedValue={field}
            setSelectedValue={setField}
            labelIndex="name"
            keyIndex={
              typeof field === "string" ? field : field ? field.name : ""
            }
          />
        )}

        <DbaDataFetchSelect
          required
          error={error && !conditionalOperator}
          disableClearable
          size="small"
          url="api/Types/Get?Name=QueryCommandFilterOperatorType"
          label="operator"
          selectedValue={conditionalOperator}
          setSelectedValue={setConditionalOperator}
          labelIndex="id"
          keyIndex={
            typeof conditionalOperator === "string"
              ? conditionalOperator
              : conditionalOperator
              ? conditionalOperator.id
              : ""
          }
        />

        <DbaDataFetchSelect
          required
          error={error && !filterVariableType.id}
          disableClearable
          size="small"
          url="api/Types/Get?Name=FilterVariableType"
          label="variableType"
          selectedValue={filterVariableType}
          setSelectedValue={setFilterVariableType}
          labelIndex="id"
          keyIndex={filterVariableType?.id || ""}
        />

        <DbaTextField
          required
          error={error && variable.trim() === ""}
          helperText="fieldIsEmptyError"
          size="small"
          label="variable"
          value={variable}
          setValue={setVariable}
        />
      </QueryConstructorModal>
    </>
  );
};
