import React, { useState, useEffect, useLayoutEffect } from "react";
import { useIntl } from "react-intl";
import { useNavigate, useParams } from "react-router-dom";
import { SaveButton, LoadingScreen } from "../../components/common";
import Box from "@mui/material/Box";
import {
  Alert,
  Chip,
  Snackbar,
  Stack,
  Switch,
  Typography,
} from "@mui/material";
import Button from "@mui/material/Button";
import { DbaTextField } from "../../DbaComponents";
import {
  useLazyGetUserQuery,
  useRegisterUserMutation,
  useUpdateUserMutation,
} from "../../features/serviceSlices/serviceHooks";
import { FetchBaseQueryErrorType } from "../../features/serviceSlices/SharedTypes";
import { useSnackbar } from "../../utils/hooks/useSnackbar";
import { DbaConfirmDialog } from "../../DbaComponents/DbaConfirmDialog";

const SHOW_SNACKBAR_PERIOD = 10000;

export const User = () => {
  const params = useParams<Record<string, string | undefined>>();
  const editMode = Object.keys(params).length > 0;
  const intl = useIntl();
  const navigate = useNavigate();

  const [firstName, setFirstName] = useState<string>("");
  const [secondName, setSecondName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [userName, setUserName] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [email, setEmail] = useState<string>("");

  const [isCheckedSendPassword, setIsCheckedSendPassword] =
    useState<boolean>(true);
  const [isCheckedAutoGen, setIsCheckedAutoGen] = useState<boolean>(true);
  const [getUserData, userData] = useLazyGetUserQuery();
  const [createUser, createResponse] = useRegisterUserMutation();
  const [updateUser, updateResponse] = useUpdateUserMutation();
  const [error, setError] = useState<boolean>(false);
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] =
    useState<boolean>(false);

  const { isShowSnackbar, snackbarMessage, snackbarSeverity, blinkSnackbar } =
    useSnackbar();

  const getSnackbar = (
    <Snackbar
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      open={isShowSnackbar}
      autoHideDuration={null}
    >
      <Alert elevation={6} severity={snackbarSeverity} sx={{ width: "100%" }}>
        {snackbarMessage}
      </Alert>
    </Snackbar>
  );

  const validationError =
    !firstName.trim() ||
    !secondName.trim() ||
    !lastName.trim() ||
    !email.trim() ||
    !userName.trim() ||
    ((password.length < 5 || password.includes(" ")) && !isCheckedAutoGen);

  const onSubmitFormHandler = () => {
    setError(false);
    if (
      editMode
        ? updateResponse.status === "pending" ||
          updateResponse.status === "fulfilled"
        : createResponse.status === "pending" ||
          createResponse.status === "fulfilled"
    )
      return;
    if (validationError) {
      setError(true);
    } else {
      if (editMode && params.id) {
        updateUser({
          id: parseInt(params.id),
          lastName: lastName.trim(),
          firstName: firstName.trim(),
          secondName: secondName.trim(),
          userName: userName.trim(),
          email: email.trim(),
        });
      } else {
        if (isCheckedAutoGen && !isCheckedSendPassword) {
          blinkSnackbar(
            intl.messages[
              "generatingPasswordWithoutSendingErrorMessage"
            ] as string,
            "error",
            SHOW_SNACKBAR_PERIOD
          );
          return;
        }
        setError(false);
        createUser({
          lastName: lastName.trim(),
          firstName: firstName.trim(),
          secondName: secondName.trim(),
          userName: userName.trim(),
          password: password.trim(),
          email: email.trim(),
          sendPassword: isCheckedSendPassword,
        });
      }
    }
  };

  const onConfirmCancelClick = () => {
    navigate(-1);
  };

  const isFetchBaseQueryErrorType = (
    error: any
  ): error is FetchBaseQueryErrorType => "status" in error;

  useEffect(() => {
    if (createResponse.isError) {
      if (isFetchBaseQueryErrorType(createResponse.error)) {
        setError(true);
        blinkSnackbar(
          createResponse.error.data.message,
          "error",
          SHOW_SNACKBAR_PERIOD
        );
      }
    }
  }, [createResponse, blinkSnackbar]);

  useEffect(() => {
    if (editMode && updateResponse.isError) {
      if (isFetchBaseQueryErrorType(updateResponse.error)) {
        setError(true);
        blinkSnackbar(
          updateResponse.error.data.message,
          "error",
          SHOW_SNACKBAR_PERIOD
        );
      }
    }
  }, [updateResponse, editMode, blinkSnackbar]);

  useLayoutEffect(() => {
    if (editMode) {
      const id = parseInt(params.id!);
      if (id !== undefined) getUserData(id);
    }
  }, [editMode, params.id, getUserData]);

  const handleSwitchPassGen = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsCheckedAutoGen(event.target.checked);
    setPassword("");
  };

  const handleSwitchSendPassword = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setIsCheckedSendPassword(event.target.checked);
  };

  useEffect(() => {
    if (userData.isSuccess && editMode) {
      if (userData.data?.lastName) {
        setLastName(userData.data?.lastName);
      }
      if (userData.data?.firstName) {
        setFirstName(userData.data?.firstName);
      }
      if (userData.data?.secondName) {
        setSecondName(userData.data?.secondName);
      }
      if (userData.data?.userName) {
        setUserName(userData.data?.userName);
      }
      if (userData.data?.email) {
        setEmail(userData.data?.email);
      }
    }
  }, [editMode, userData.data, userData.isSuccess]);

  const confirmCancelModal = (
    <DbaConfirmDialog
      isOpen={isConfirmDialogOpen}
      title="cancelConfirmTitle"
      message="cancelConfirmMessage"
      onAccept={onConfirmCancelClick}
      onDecline={() => setIsConfirmDialogOpen(false)}
    />
  );

  const passwordHelperText = password.includes(" ")
    ? (intl.messages["passwordMustNotIncludeSpaces"] as string)
    : password.length < 5
    ? (intl.messages["passwordLength"] as string)
    : "";

  return editMode && userData.isLoading && firstName.length === 0 ? (
    <LoadingScreen />
  ) : (
    <Box component="form" onSubmit={onSubmitFormHandler}>
      <Stack direction="row" spacing={2} sx={{ mb: "1rem" }}>
        <Button
          onClick={() => setIsConfirmDialogOpen(true)}
          sx={{ width: "fit-content" }}
          color="error"
          variant="contained"
        >
          {intl.messages["cancel"]}
        </Button>
        <SaveButton
          redirectUrl="/users"
          onClick={onSubmitFormHandler}
          status={editMode ? updateResponse.status : createResponse.status}
        />
      </Stack>
      <Stack direction="column" spacing={2}>
        <DbaTextField
          required
          error={error && !lastName.trim()}
          value={lastName}
          setValue={setLastName}
          size="medium"
          label="lastName"
          helperText="fieldIsEmptyError"
        />
        <DbaTextField
          required
          error={error && !firstName.trim()}
          value={firstName}
          setValue={setFirstName}
          size="medium"
          label="firstName"
          helperText="fieldIsEmptyError"
        />
        <DbaTextField
          required
          error={error && !secondName.trim()}
          value={secondName}
          setValue={setSecondName}
          size="medium"
          label="secondName"
          helperText="fieldIsEmptyError"
        />
        <DbaTextField
          required
          error={error && !userName.trim()}
          value={userName}
          setValue={setUserName}
          size="medium"
          label="userName"
          helperText="fieldIsEmptyError"
        />
        {editMode ? (
          ""
        ) : (
          <>
            <Stack
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              spacing={2}
            >
              <Typography variant="button">
                {intl.messages["generatePassword"]}
              </Typography>
              <Switch
                checked={isCheckedAutoGen}
                onChange={handleSwitchPassGen}
                inputProps={{ "aria-label": "controlled" }}
              />
            </Stack>
            {!isCheckedAutoGen ? (
              <DbaTextField
                value={password}
                setValue={setPassword}
                size="medium"
                label="password"
                type="password"
                error={error && (password.length < 5 || password.includes(" "))}
                helperText={passwordHelperText}
              />
            ) : (
              ""
            )}
          </>
        )}
        <DbaTextField
          required
          error={error && !email.trim()}
          value={email}
          setValue={setEmail}
          size="medium"
          label="emailAddress"
          helperText="fieldIsEmptyError"
        />
        {editMode ? (
          ""
        ) : (
          <Stack
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={2}
          >
            <Typography variant="button">
              {intl.messages["sendPassword"]}
            </Typography>
            <Switch
              checked={isCheckedSendPassword}
              onChange={handleSwitchSendPassword}
              inputProps={{ "aria-label": "controlled" }}
            />
          </Stack>
        )}
        {editMode ? (
          <Stack
            direction="row"
            justifyContent="flex-start"
            flexWrap="wrap"
            spacing={1}
            rowGap="5px"
          >
            {userData.data?.groups.length === 0 ? (
              <Alert severity="info">{intl.messages["userHasNoGroups"]}</Alert>
            ) : (
              <>
                <Typography variant="subtitle1">
                  {intl.messages["groupsOfUser"]}:
                </Typography>
                {userData.data?.groups.map((group) => (
                  <Chip label={group.name} variant="outlined" />
                ))}
              </>
            )}
          </Stack>
        ) : (
          ""
        )}
        {getSnackbar}
      </Stack>
      {confirmCancelModal}
    </Box>
  );
};
