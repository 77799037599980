import { useState } from "react";
import { DbaDataFetchSelect } from "../../../DbaComponents";
import { CSSExpressionContainer } from "../QueryConstructor.styles";
import { QueryConstructorModal } from "./QueryConstructorModal";
import { useIntl } from "react-intl";

export const Group = ({
  data,
  onSaveChanges,
  tableName,
  deleteHandler,
  dataSourceId,
}: {
  data: string;
  onSaveChanges: (arg: string) => void;
  tableName: string;
  deleteHandler: (arg: string) => void;
  dataSourceId: string;
}) => {
  const intl = useIntl();

  const [field, setField] = useState<any>(data);
  const [open, setOpen] = useState(data === "" ? true : false);
  const [error, setError] = useState(false);

  const handleOpen = () => setOpen(true);

  const onSaveExpressionHandler = () => {
    if (field?.name) {
      onSaveChanges(field.name);
      setOpen(false);
    } else {
      setError(true);
    }
  };

  const onDeleteGroupHandler = () => {
    deleteHandler(data);
    setOpen(false);
  };

  return (
    <>
      <CSSExpressionContainer onClick={handleOpen}>
      {(intl.messages["byColumn"] as string).toLowerCase()}{" "}
      <span style={{ fontWeight: 800 }}>{data}</span>
      </CSSExpressionContainer>
      <QueryConstructorModal
        open={open}
        title={intl.messages["groupBy"] as string}
        saveHandler={onSaveExpressionHandler}
        deleteHandler={onDeleteGroupHandler}
      >
        <DbaDataFetchSelect
          required
          error={error && !field?.name}
          size="small"
          url={`api/Query/GetFields?DataSourceID=${dataSourceId}&TableName=${tableName}`}
          label="fields"
          selectedValue={field}
          setSelectedValue={setField}
          labelIndex="name"
          keyIndex={data ? data : ""}
          disableClearable
        />
      </QueryConstructorModal>
    </>
  );
};
