import {
  Alert,
  Button,
  InputAdornment,
  Snackbar,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";

import {
  CSSForm,
  CSSFormBox,
  CSSLoginFormContainer,
  CSSLogoContainer,
  CSSPasswordContainer,
  CSSWelcomeText,
} from "./AuthStyles";
import { useIntl } from "react-intl";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useRegisterUserMutation } from "../../features/serviceSlices/serviceHooks";
import { FetchBaseQueryErrorType } from "../../features/serviceSlices/SharedTypes";

export const RegistrationForm = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const [firstName, setFirstName] = useState<string>("");
  const [secondName, setSecondName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [userName, setUserName] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [repeatPassword, setRepeatPassword] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [createUser, createResponse] = useRegisterUserMutation();
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showRepeatPassword, setShowRepeatPassword] = useState<boolean>(false);

  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [snackbarMessage, setSnackbarMessage] = useState<string>("");

  const [error, setError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");

  const isFetchBaseQueryErrorType = (
    error: any
  ): error is FetchBaseQueryErrorType => "status" in error;

  const fieldsAreValid = () =>
    firstName.length !== 0 &&
    secondName.length !== 0 &&
    lastName.length !== 0 &&
    userName.length !== 0 &&
    password.length >= 5 &&
    !password.includes(" ") &&
    !repeatPassword.includes(" ") &&
    password === repeatPassword &&
    email.length !== 0;

  const handleSubmit = (event: React.SyntheticEvent) => {
    event.preventDefault();
    if (fieldsAreValid()) {
      setError(false);
      createUser({
        lastName: lastName.trim(),
        firstName: firstName.trim(),
        secondName: secondName.trim(),
        userName: userName.trim(),
        password: password.trim(),
        email: email.trim(),
        sendPassword: false,
      });
    } else {
      setError(true);
    }
  };

  const onFieldChangeHandler = (
    value: string,
    setter: React.Dispatch<React.SetStateAction<string>>
  ) => {
    if (errorMessage !== "") {
      setErrorMessage("");
    }
    setter(value);
  };

  useEffect(() => {
    if (createResponse.isError) {
      if (isFetchBaseQueryErrorType(createResponse.error)) {
        setSnackbarMessage(
          createResponse.error.data?.message
            ? createResponse.error.data?.message
            : (intl.messages["error"] as string)
        );
        setOpenSnackbar(true);
      }
    } else if (createResponse.isSuccess) {
      setSnackbarMessage(`${intl.messages["successRegistration"]}`);
      setOpenSnackbar(true);
      setTimeout(() => navigate("/login"), 3500);
    }
  }, [createResponse, intl.messages, navigate]);

  return (
    <CSSLoginFormContainer>
      <CSSFormBox>
        <CSSLogoContainer>
          <CSSWelcomeText>{intl.messages["registration"]}</CSSWelcomeText>
        </CSSLogoContainer>
        <CSSForm autoComplete="off" onSubmit={handleSubmit}>
          <input
            style={{
              position: "absolute",
              bottom: 0,
              opacity: 0,
              left: 0,
              pointerEvents: "none",
            }}
            type="text"
            name="fakeusernameremembered"
          />
          <input
            style={{
              position: "absolute",
              bottom: 0,
              opacity: 0,
              left: 0,
              pointerEvents: "none",
            }}
            type="password"
            name="fakepasswordremembered"
          />
          <TextField
            required
            error={error && lastName.length === 0}
            helperText={
              error && lastName.length === 0
                ? intl.messages["requiredField"]
                : errorMessage !== undefined
                ? errorMessage
                : ""
            }
            fullWidth
            value={lastName}
            onChange={(event) =>
              onFieldChangeHandler(event.target.value, setLastName)
            }
            label="Фамилия"
            variant="outlined"
            inputProps={{ maxLength: 256 }}
            size={"small"}
          />
          <TextField
            required
            error={error && firstName.length === 0}
            helperText={
              error && firstName.length === 0
                ? intl.messages["requiredField"]
                : errorMessage !== undefined
                ? errorMessage
                : ""
            }
            fullWidth
            value={firstName}
            onChange={(event) =>
              onFieldChangeHandler(event.target.value, setFirstName)
            }
            label="Имя"
            variant="outlined"
            inputProps={{ maxLength: 256 }}
            size={"small"}
          />
          <TextField
            required
            error={error && secondName.length === 0}
            helperText={
              error && secondName.length === 0
                ? intl.messages["requiredField"]
                : errorMessage !== undefined
                ? errorMessage
                : ""
            }
            fullWidth
            value={secondName}
            onChange={(event) =>
              onFieldChangeHandler(event.target.value, setSecondName)
            }
            label="Отчество"
            variant="outlined"
            inputProps={{ maxLength: 256 }}
            size={"small"}
          />
          <TextField
            required
            error={error && userName.length === 0}
            helperText={
              error && userName.length === 0
                ? intl.messages["requiredField"]
                : errorMessage !== undefined
                ? errorMessage
                : ""
            }
            fullWidth
            value={userName}
            onChange={(event) =>
              onFieldChangeHandler(event.target.value, setUserName)
            }
            label="Логин"
            variant="outlined"
            inputProps={{ maxLength: 256 }}
            size={"small"}
          />
          <TextField
            required
            error={error && email.length === 0}
            helperText={
              error && email.length === 0
                ? intl.messages["requiredField"]
                : errorMessage !== undefined
                ? errorMessage
                : ""
            }
            fullWidth
            value={email}
            onChange={(event) =>
              onFieldChangeHandler(event.target.value, setEmail)
            }
            label={intl.messages["email"]}
            variant="outlined"
            inputProps={{ maxLength: 256 }}
            size={"small"}
          />
          <CSSPasswordContainer>
            <TextField
              error={error && (password.length < 5 || password.includes(" "))}
              helperText={
                error && password.length === 0
                  ? intl.messages["requiredField"]
                  : error && password.includes(" ")
                  ? intl.messages["passwordMustNotIncludeSpaces"]
                  : error && password.length < 5
                  ? intl.messages["passwordLength"]
                  : ""
              }
              required
              type={showPassword ? "text" : "password"}
              inputProps={{ maxLength: 256 }}
              InputProps={{
                endAdornment:
                  password.length > 0 ? (
                    <InputAdornment
                      onClick={() => setShowPassword(!showPassword)}
                      position="end"
                    >
                      {showPassword ? (
                        <VisibilityOff sx={{ cursor: "pointer" }} />
                      ) : (
                        <Visibility sx={{ cursor: "pointer" }} />
                      )}
                    </InputAdornment>
                  ) : (
                    ""
                  ),
              }}
              fullWidth
              value={password}
              onChange={(event) =>
                onFieldChangeHandler(event.target.value, setPassword)
              }
              label="Пароль"
              variant="outlined"
              size={"small"}
            />
            <TextField
              error={
                (error && repeatPassword.length < 5) ||
                (error && repeatPassword.includes(" ")) ||
                (error && repeatPassword !== password)
              }
              helperText={
                error && repeatPassword.length === 0
                  ? intl.messages["requiredField"]
                  : error && repeatPassword.includes(" ")
                  ? intl.messages["passwordMustNotIncludeSpaces"]
                  : error && repeatPassword.length < 5
                  ? intl.messages["passwordLength"]
                  : error && repeatPassword !== password
                  ? intl.messages["passwordMatch"]
                  : ""
              }
              required
              type={showRepeatPassword ? "text" : "password"}
              inputProps={{ maxLength: 256 }}
              InputProps={{
                endAdornment:
                  password.length > 0 ? (
                    <InputAdornment
                      onClick={() => setShowRepeatPassword(!showRepeatPassword)}
                      position="end"
                    >
                      {showRepeatPassword ? (
                        <VisibilityOff sx={{ cursor: "pointer" }} />
                      ) : (
                        <Visibility sx={{ cursor: "pointer" }} />
                      )}
                    </InputAdornment>
                  ) : (
                    ""
                  ),
              }}
              fullWidth
              value={repeatPassword}
              onChange={(event) =>
                onFieldChangeHandler(event.target.value, setRepeatPassword)
              }
              label="Повторите пароль"
              variant="outlined"
              size={"small"}
            />
          </CSSPasswordContainer>
          <Button type="submit" variant="contained">
            {intl.messages["registration"]}
          </Button>
          <Button onClick={() => navigate("/login")} variant="contained">
            {intl.messages["cancel"]}
          </Button>
        </CSSForm>
        <Snackbar
          autoHideDuration={3000}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={openSnackbar}
          onClose={() => setOpenSnackbar(false)}
        >
          <Alert severity={createResponse.isError ? "error" : "success"}>
            {snackbarMessage.length !== 0
              ? snackbarMessage
              : intl.messages["success"]}
          </Alert>
        </Snackbar>
      </CSSFormBox>
    </CSSLoginFormContainer>
  );
};
