import Typography from "@mui/material/Typography";
import InfoIcon from "@mui/icons-material/Info";
import CloseIcon from "@mui/icons-material/Close";
import useMediaQuery from "@mui/material/useMediaQuery";
import { DbaWidgetInfoModalProps } from "./DbaWidgetInfoMofal.types";
import {
  CSSModal,
  CSSDialogContainer,
  CSSTextContainer,
} from "./StyledComponents";
import { DbaIconButton } from "../DbaIconButton";
import { useTheme } from "@mui/material/styles";
import { useIntl } from "react-intl";
import { Link as RouterLink } from "react-router-dom";
import { useAppSelector } from "../../utils/reduxHooks";
import { Link, Paper, Stack } from "@mui/material";

export const DbaWidgetInfoModal = ({
  isOpen,
  onClose,
  data,
}: DbaWidgetInfoModalProps) => {
  const intl = useIntl();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const { authorizationInfo } = useAppSelector((state) => state.app);

  const getDataSourceInfo = () => {
    const dataSourcesInfo = data?.dataSourceInfo;
    if (!dataSourcesInfo || dataSourcesInfo.length === 0) {
      return null;
    }
    if (authorizationInfo?.isAdmin) {
      return dataSourcesInfo?.map((info, key) => (
        <Link
          key={key}
          to={{ pathname: `/edit-datasource/${info.dataSourceId}` }}
          component={RouterLink}
        >
          <span>{info.dataSourceName}</span>
        </Link>
      ));
    } else {
      return dataSourcesInfo?.map((info, key) => (
        <span key={key}>{info.dataSourceName}</span>
      ));
    }
  };

  const descriptionBlock =
    data?.description && data?.description?.length !== 0 ? (
      <Paper style={{ padding: "8px" }}>
        <Typography variant="caption">{`${intl.messages["description"]}: `}</Typography>
        <Typography>{`${data?.description && data?.description}`}</Typography>
      </Paper>
    ) : null;

  return (
    <CSSModal fullScreen={fullScreen} open={isOpen} onClose={onClose}>
      <CSSDialogContainer>
        <InfoIcon />
        <CSSTextContainer>
          <Typography variant="h6" component="h2">
            {data?.label && data?.label}
          </Typography>
          {descriptionBlock}
          {data?.dataSourceInfo ? (
            <Paper style={{ padding: "8px" }}>
              <Typography variant="caption">{`${intl.messages["datasources"]}:`}</Typography>
              <Stack direction={"column"} spacing={1}>
                {getDataSourceInfo()}
              </Stack>
            </Paper>
          ) : null}
        </CSSTextContainer>
        <DbaIconButton size="medium" icon={<CloseIcon />} onClick={onClose} />
      </CSSDialogContainer>
    </CSSModal>
  );
};
