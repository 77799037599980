import * as React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete, { autocompleteClasses } from "@mui/material/Autocomplete";
import useMediaQuery from "@mui/material/useMediaQuery";
import Popper from "@mui/material/Popper";
import { useTheme, styled } from "@mui/material/styles";
// @ts-ignore
import { VariableSizeList, ListChildComponentProps } from "react-window";
import { useIntl } from "react-intl";
import CircularProgress from "@mui/material/CircularProgress";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { DbaSelectType } from "./Types";
import { InputIcon } from "./InputIcon";

const LISTBOX_PADDING = 8; // px
const STRING_LENGTH_TO_COLUMN_HEIGHT_RATIO = 2.5; //зависимость высоты элемента в селекте от длины названия элемента

function renderRow({ data, index, style }: ListChildComponentProps) {
  const dataSet = data[index];
  const inlineStyle = {
    ...style,
    top: (style.top as number) + LISTBOX_PADDING,
  };
  return (
    <li {...dataSet[0]} style={inlineStyle}>
      {dataSet[1].optionLabel
        ? dataSet[1][dataSet[1].optionLabel]
        : dataSet[1].name}
    </li>
  );
}

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef<HTMLDivElement>((props, ref) => {
  const outerProps = React.useContext(OuterElementContext);
  return <div ref={ref} {...props} {...outerProps} />;
});

function useResetCache(data: any) {
  const ref = React.useRef<VariableSizeList>(null);
  React.useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true);
    }
  }, [data]);
  return ref;
}
type TExtendedListboxProps = {
  isDynamicHeight?: boolean;
} & React.HTMLAttributes<HTMLUListElement>;

// Adapter for react-window
const ListboxComponent = React.forwardRef<
  HTMLDivElement,
  TExtendedListboxProps
>(function ListboxComponent(props, ref) {
  const { children, isDynamicHeight, ...other } = props;
  const itemData: React.ReactChild[] = [];
  (children as React.ReactChild[]).forEach(
    (item: React.ReactChild & { children?: React.ReactChild[] }) => {
      itemData.push(item);
      itemData.push(...(item.children || []));
    }
  );

  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up("sm"), {
    noSsr: true,
  });

  const itemCount = itemData.length;
  const itemSize = smUp ? 36 : 48;

  const getChildSize = (child: React.ReactChild) => {
    if (isDynamicHeight && child && Array.isArray(child) && child.length > 0) {
      const rowHeight =
        child[0]?.key?.length / STRING_LENGTH_TO_COLUMN_HEIGHT_RATIO ?? 0;
      return itemSize + rowHeight;
    } else {
      return itemSize;
    }
  };

  const getHeight = () => {
    if (itemCount > 8) {
      return 8 * itemSize;
    }
    return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
  };

  const gridRef = useResetCache(itemCount);

  return (
    <div ref={ref}>
      <OuterElementContext.Provider value={other}>
        <VariableSizeList
          itemData={itemData}
          height={getHeight() + 2 * LISTBOX_PADDING}
          ref={gridRef}
          outerElementType={OuterElementType}
          innerElementType="ul"
          itemSize={(index: any) => getChildSize(itemData[index])}
          overscanCount={5}
          itemCount={itemCount}
        >
          {renderRow}
        </VariableSizeList>
      </OuterElementContext.Provider>
    </div>
  );
});

const StyledPopper = styled(Popper)({
  [`& .${autocompleteClasses.listbox}`]: {
    boxSizing: "border-box",
    "& ul": {
      padding: 0,
      margin: 0,
    },
  },
});

export function DbaSelect({
  disableClearable,
  disabled,
  options,
  label,
  selectedValue,
  setSelectedValue,
  error = false,
  helperText = "",
  required,
  loadingData,
  optionLabel,
  loadingError,
  open,
  setOpen,
  size = "medium",
  multiple,
  width,
  minWidth,
  isDynamicHeight = false,
  selectOnFocus,
  showClearIcon,
}: DbaSelectType) {
  const intl = useIntl();
  const selectHelperText =
    error && required ? intl.messages["requiredField"] : helperText;

  return (
    <Autocomplete
      multiple={multiple}
      sx={{
        minWidth: minWidth !== undefined ? minWidth : "250px",
        width: width !== undefined ? width : "auto",
      }}
      disabled={disabled}
      size={size}
      open={open}
      selectOnFocus={selectOnFocus}
      onOpen={() => setOpen && setOpen(true)}
      onClose={() => setOpen && setOpen(false)}
      noOptionsText={intl.messages["noOptions"]}
      value={selectedValue === undefined ? { name: "" } : selectedValue}
      onChange={(e, value) => setSelectedValue(value)}
      id="virtualize-demo"
      disableListWrap
      PopperComponent={StyledPopper}
      ListboxComponent={ListboxComponent}
      ListboxProps={
        { isDynamicHeight } as React.HTMLAttributes<HTMLUListElement>
      }
      options={options}
      loading={loadingData}
      loadingText="Загрузка..."
      renderInput={({ InputProps, ...params }) => {
        return (
          <TextField
            {...params}
            variant="outlined"
            label={
              label !== undefined
                ? intl.messages[label] !== undefined
                  ? intl.messages[label]
                  : label
                : ""
            }
            error={error}
            helperText={selectHelperText}
            InputProps={{
              ...InputProps,
              endAdornment:
                loadingError && selectedValue === null ? (
                  <InputIcon
                    icon={<ErrorOutlineIcon color="error" />}
                    text="error"
                  />
                ) : loadingData ? (
                  <CircularProgress size={20} />
                ) : showClearIcon ? (
                  InputProps.endAdornment
                ) : null,
            }}
            InputLabelProps={{ sx: { zIndex: 0 } }}
            required={required}
          />
        );
      }}
      getOptionLabel={(option: any) =>
        optionLabel ? option[optionLabel] : option.name || ""
      }
      renderOption={(props, option) => [props, { ...option, optionLabel }]}
      disableClearable={disableClearable}
    />
  );
}
