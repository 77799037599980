import { useEffect } from "react";
import { useGetDataWidgetDataQuery } from "../../../../features/serviceSlices/serviceHooks";
import { useAppSelector } from "../../../../utils/reduxHooks";
import { LoadingScreen, ErrorMessage } from "../../../common";
import type { WidgetProps } from "../Widgets.types";
import { DataWidget } from "../../common";
import { skipToken } from "@reduxjs/toolkit/query";
import { AuthGuard } from "../../../Guards/AuthGuard/AuthGuard";
import { WidgetMenu } from "../../common/WidgetMenu";
import { CSSWrapper } from "../../common/WidgetLayout/WidgetLayout.styles";
import { FetchBaseQueryErrorType } from "../../../../features/serviceSlices/SharedTypes";

export const Default = ({
  id,
  setBody,
  setResizeHandlerColor,
}: WidgetProps) => {
  const {
    type,
    startDate,
    endDate,
    role,
    filters,
    editMode,
    authorizationInfo,
  } = useAppSelector((state) => state.app);
  
  const DefaultWidgetData = useGetDataWidgetDataQuery(
    type !== "NotSet" && type
      ? {
          widgetId: id,
          dashboardId: role.id,
          startDate,
          endDate,
          periodType: type,
          filters: JSON.stringify(filters),
        }
      : skipToken
  );

  useEffect(() => {
    if (
      DefaultWidgetData.data &&
      DefaultWidgetData.data.widgetData?.widgetSettings?.settings?.widget?.body
    ) {
      setBody(
        DefaultWidgetData.data.widgetData?.widgetSettings.settings.widget.body
      );
    }
  }, [DefaultWidgetData.data, setBody]);

  useEffect(() => {
    if (
      DefaultWidgetData.data &&
      DefaultWidgetData.data.widgetData?.widgetSettings?.settings?.widget
        ?.fontColor
    ) {
      setResizeHandlerColor(
        DefaultWidgetData.data.widgetData?.widgetSettings.settings.widget
          ?.fontColor
      );
    }
  }, [DefaultWidgetData.data, setResizeHandlerColor]);

  const getErrorMessage = () => {
    return (
      <CSSWrapper>
        <ErrorMessage
          error={DefaultWidgetData.error as FetchBaseQueryErrorType}
          isShowTooltip={!!authorizationInfo?.isAdmin}
        />
        <AuthGuard>
          <WidgetMenu
            dashboardId={role.id}
            widgetID={id}
            type={"Default"}
            editMode={editMode}
            isError={true}
          />
        </AuthGuard>
      </CSSWrapper>
    );
  };

  return DefaultWidgetData.isLoading || DefaultWidgetData.isFetching ? (
    <LoadingScreen />
  ) : DefaultWidgetData.isSuccess ? (
    <DataWidget data={DefaultWidgetData.data.widgetData} />
  ) : (
    getErrorMessage()
  );
};
