import { rootApi } from "../../serviceRoot";
import { GetFilterWidgetDataRequest } from "../FilterWidget/Types";
import {
  TImageWidgetDataResponse,
  TImageWidgetResponse,
  TImageWidgetSaveRequest,
  TImageWidgetSaveResponse,
} from "./Types";
import { WidgetDataRequest } from "../../Widget/Types";

export const imageWidgetApiSlice = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    getImageWidget: builder.mutation<
      TImageWidgetResponse,
      WidgetDataRequest
    >({
      query: (payload) => ({
        url: "api/ImageWidget/Get",
        method: "POST",
        body: payload,
      }),
    }),
    getImageWidgetData: builder.query<
      TImageWidgetDataResponse,
      GetFilterWidgetDataRequest
    >({
      query: (params) => ({
        url: "api/Widget/GetData",
        method: "GET",
        params: {
          widgetId: params.widgetId,
          dashboardId: params.dashboardId,
        },
      }),
      providesTags: (result, error, arg) =>
        result
          ? [{ id: result.widgetData.id, type: "ImageWidget" }]
          : ["ImageWidget"],
    }),
    createImageWidget: builder.mutation<any, TImageWidgetSaveRequest>({
      query: (payload) => ({
        url: "api/ImageWidget/Create",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["Widgets"],
    }),
    updateImageWidget: builder.mutation<
      TImageWidgetSaveResponse,
      TImageWidgetSaveRequest
    >({
      query: (payload) => ({
        url: "api/ImageWidget/Update",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: (result, error, arg) => {
        return result
          ? [{ type: "ImageWidget", id: result.id }, "Widgets"]
          : ["ImageWidget", "Widgets"];
      },
    }),
  }),
});

export const {
  useGetImageWidgetDataQuery,
  useCreateImageWidgetMutation,
  useUpdateImageWidgetMutation,
  useGetImageWidgetMutation,
  useLazyGetImageWidgetDataQuery,
} = imageWidgetApiSlice;
