import {
  DbaTextField,
  DbaDatepicker,
  DbaErrorBoundary,
  DbaCheckbox,
  DbaMonacoEditor,
} from "..";
import {
  DbaInputsGeneratorProps,
  FieldTypes,
  ProviderSettingsField,
} from "./DbaInputsGenerator.types";
import { useEffect, useCallback, memo } from "react";
import styles from "./DbaInputsGenerator.module.css";
import { CSSEditorContainer } from "./DbaInputsGenerator.styles";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import { QueryConstructor } from "../../Pages";
import { useCreateQueryMutation } from "../../features/serviceSlices/serviceHooks";
import { QueryObjectType } from "../../components/QueryConstructor/QueryConstructor.types";
import { DbaDataFetchSelect } from "../DbaSelect/DbaDataFetchSelect";
import { FiltersConstructor } from "../../components/FiltersConstructor/FiltersConstructor";
import Typography from "@mui/material/Typography";
import { DbaFileSelector } from "../DbaFileSelector/DbaFileSelector";
import { Stack, Tooltip } from "@mui/material";
import { InfoOutlined } from "@mui/icons-material";
import { EnumSelect } from "../DbaSelect/DbaEnumSelect/DbaEnumSelect";

const inlineFieldTypes: FieldTypes[] = [
  "Date",
  "Number",
  "Password",
  "String",
  "TextArea",
  "SqlConstructor",
  "DbSelect",
];
const fullWidthFieldTypes: FieldTypes[] = ["Json", "Sql"];

export const DbaInputsGenerator = memo(
  ({
    fields,
    state,
    setState,
    error,
    widgetType,
    dataSourceId,
  }: DbaInputsGeneratorProps) => {
    const parsedState = Object.fromEntries(
      Object.entries(JSON.parse(state)).map(([key, val]) => [
        key.toLowerCase(),
        val,
      ])
    );
    const [createQuery, createQueryResponse] = useCreateQueryMutation();
    const setParsedData = useCallback(
      (value: any, key: string) => {
        const stateObj = {
          ...parsedState,
          [key]: value,
        };
        const stringifiedObj = JSON.stringify(stateObj);
        setState(stringifiedObj);
      },
      [parsedState, setState]
    );

    const sortedArr = [...fields]
      .sort((a: ProviderSettingsField, b: ProviderSettingsField) =>
        inlineFieldTypes.includes(a.type) &&
        fullWidthFieldTypes.includes(b.type)
          ? -1
          : 0
      )
      .map((field) => ({ ...field, name: field.name.toLowerCase() }));

    useEffect(() => {
      if (createQueryResponse.data) {
        setParsedData(createQueryResponse.data.query, "script");
      }
    }, [createQueryResponse.data, setParsedData]);

    const parsedQueryObject = parsedState.queryobject
      ? (JSON.parse(parsedState.queryobject as string) as QueryObjectType)
      : null;

    const parsedFilters = parsedState.filters
      ? JSON.parse(parsedState.filters as string)
      : null;

    const isManualTextControl = !sortedArr.find(
      (field) => field.type === "SqlConstructor"
    );
    const isEditorDisabled = Boolean(
      sortedArr.find((object) => object.type === "SqlConstructor")
    );
    const tooltip = (title: string | null) =>
      title ? (
        <Tooltip placement="right" arrow={true} title={title}>
          <InfoOutlined sx={{ width: 25, height: 25, color: "#888888" }} />
        </Tooltip>
      ) : null;

    return (
      <>
        {sortedArr.map((field) => {
          switch (field.type) {
            case "Filters":
              return (
                <FiltersConstructor
                  key={field.name}
                  data={parsedFilters}
                  setParsedData={setParsedData}
                  dataSourceId={dataSourceId}
                  tableName={
                    parsedQueryObject?.table ? parsedQueryObject.table : null
                  }
                  isManualTextControl={isManualTextControl}
                />
              );
            case "File":
              return (
                <DbaErrorBoundary key={field.name}>
                  <DbaFileSelector
                    onFileSelect={(value) => setParsedData(value, field.name)}
                    selectedFileId={parsedState[field.name] as string}
                    attributes={field.attributes}
                  />
                </DbaErrorBoundary>
              );
            case "SqlConstructor":
              return (
                <DbaErrorBoundary key={field.name}>
                  <QueryConstructor
                    widgetType={widgetType!}
                    key={field.name}
                    data={parsedQueryObject}
                    queryCommand={state}
                    setParsedData={setParsedData}
                    getQuery={createQuery}
                    getQueryResponse={createQueryResponse}
                    dataSourceId={dataSourceId}
                  />
                </DbaErrorBoundary>
              );
            case "Sql":
              return (
                <CSSEditorContainer key={field.name}>
                  <Typography variant="h6">
                    {field.caption}
                    {field.isRequired ? "*" : ""}
                  </Typography>

                  <DbaMonacoEditor
                    key={field.name}
                    className={
                      error &&
                      field.isRequired &&
                      (parsedState.script === "" ||
                        Object.keys(parsedState).length === 0)
                        ? styles.error
                        : ""
                    }
                    language="sql"
                    value={parsedState[field.name] as string}
                    onChange={(value) => setParsedData(value, field.name)}
                    readOnly={isEditorDisabled}
                  />
                </CSSEditorContainer>
              );
            case "Json":
              return (
                <CSSEditorContainer key={field.name}>
                  <Typography variant="h6">{field.caption}</Typography>
                  <DbaMonacoEditor
                    key={field.name}
                    className={
                      error &&
                      field.isRequired &&
                      (parsedState.Script === "" ||
                        Object.keys(parsedState).length === 0)
                        ? styles.error
                        : ""
                    }
                    language="json"
                    value={parsedState[field.name] as string}
                    onChange={(value) => setParsedData(value, field.name)}
                  />
                </CSSEditorContainer>
              );
            case "TextArea":
              return (
                <CSSEditorContainer key={field.name}>
                  <Typography variant="h6">{field.caption}</Typography>
                  <TextareaAutosize
                    value={(parsedState[field.name] as string | number) || ""}
                    onChange={(value) => setParsedData(value, field.name)}
                    required={field.isRequired ? true : undefined}
                    minRows={5}
                  />
                </CSSEditorContainer>
              );
            case "String":
              return (
                <Stack
                  direction={"row"}
                  alignItems="center"
                  spacing={1}
                  key={field.name}
                >
                  <DbaTextField
                    value={(parsedState[field.name] as string) || ""}
                    setValue={(value) => setParsedData(value, field.name)}
                    required={field.isRequired ? true : undefined}
                    label={field.caption}
                    error={
                      error &&
                      field.isRequired &&
                      (typeof parsedState[field.name] !== "string" ||
                        !(parsedState[field.name] as string).trim())
                    }
                    helperText="fieldIsEmptyError"
                    maxLength={2048}
                  />
                  {tooltip(field.tooltip)}
                </Stack>
              );
            case "Date":
              return (
                <DbaDatepicker
                  width="300px"
                  label={field.caption}
                  setDate={(value: Date) => {
                    setParsedData(value.toISOString(), field.name);
                  }}
                  date={
                    parsedState[field.name]
                      ? new Date(parsedState[field.name] as string)
                      : ""
                  }
                  dateFormat="dd.MM.yyyy"
                  maxDate={new Date()}
                  key={field.name}
                  required={field.isRequired ? true : undefined}
                  error={
                    error &&
                    field.isRequired &&
                    (parsedState[field.name] === "" ||
                      typeof parsedState[field.name] !== "string")
                  }
                />
              );
            case "Number":
              return (
                <DbaTextField
                  key={field.name}
                  type="number"
                  value={(parsedState[field.name] as string) || ""}
                  setValue={(value) =>
                    setParsedData(parseInt(value), field.name)
                  }
                  required={field.isRequired ? true : undefined}
                  label={field.caption}
                  error={
                    error &&
                    field.isRequired &&
                    typeof parsedState[field.name] !== "number"
                  }
                  readyIcon
                />
              );
            case "Password":
              return (
                <DbaTextField
                  key={field.name}
                  type="password"
                  value={(parsedState[field.name] as string) || ""}
                  setValue={(value) => setParsedData(value, field.name)}
                  required={field.isRequired ? true : undefined}
                  label={field.caption}
                  error={
                    error &&
                    field.isRequired &&
                    (parsedState[field.name] === "" ||
                      typeof parsedState[field.name] !== "string")
                  }
                  readyIcon
                />
              );
            case "DbSelect":
              return (
                <DbaDataFetchSelect
                  key={field.name}
                  url={`/api/Query/GetDatabases?DataSourceID=${dataSourceId}`}
                  label={field.caption}
                  selectedValue={{ database: parsedState[field.name] || "" }}
                  setSelectedValue={(value: { database: string }) =>
                    setParsedData(value.database, field.name)
                  }
                  optionLabel="database"
                />
              );
            case "Bool":
              return (
                <DbaCheckbox
                  key={field.name}
                  checked={(parsedState[field.name] as boolean) ?? false}
                  setChecked={(value) => setParsedData(value, field.name)}
                  label={field.caption}
                />
              );
            case "Enum":
              return (
                <Stack
                  direction={"row"}
                  alignItems="center"
                  spacing={1}
                  key={field.name}
                >
                  <EnumSelect
                    enumName={field.name}
                    inputLabel={field.caption}
                    setter={(selectedValue: string) =>
                      setParsedData(selectedValue, field.name)
                    }
                    initialValue={(parsedState[field.name] as string) ?? ""}
                    firstOptionIsInitialValue
                  />
                  {tooltip(field.tooltip)}
                </Stack>
              );
            default:
              return <p></p>;
          }
        })}
      </>
    );
  }
);
