import React, { useMemo, useCallback, useState } from "react";
import {
  useReloadDatasetMutation,
  useDeleteSettingsMutation,
  useGetDatasetsQuery,
} from "../../features/serviceSlices/serviceHooks";
import { LoadingScreen, ErrorMessage } from "../../components/common";
import { DbaTable, DbaIconButton, DbaTooltip } from "../../DbaComponents";
import { DatasetTablerowType } from "../../features/serviceSlices/DataSet/Types";
import { CSSDataContainer } from "./Datasets.styles";
import { useIntl } from "react-intl";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import Link from "@mui/material/Link";
import CachedIcon from "@mui/icons-material/Cached";
import { locale } from "../../utils/locale";
import { ViewData } from "./ViewData";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DataThresholdingIcon from "@mui/icons-material/DataThresholding";
import IconButton from "@mui/material/IconButton";
import styles from "./Datasets.module.css";
import { ColumnDef } from "@tanstack/react-table";
import { EditButton } from "../../components/TableHelpers/EditButton";
import { DeleteButton } from "../../components/TableHelpers/DeleteButton";
import constants from "../../utils/constants";
import { useSnackbar } from "../../utils/hooks/hooks";
import { Alert, Snackbar } from "@mui/material";

const BIG_SIZE_TO_PUSH_EDIT_COLUMN = 2000;
const ACTION_COLUMN_SIZE = 44;

export const Datasets = () => {
  const intl = useIntl();
  const navigate = useNavigate();

  const { isShowSnackbar, snackbarMessage, snackbarSeverity, blinkSnackbar } =
    useSnackbar();

  const getSnackbar = (
    <Snackbar
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      open={isShowSnackbar}
      autoHideDuration={null}
    >
      <Alert elevation={6} severity={snackbarSeverity} sx={{ width: "100%" }}>
        {snackbarMessage}
      </Alert>
    </Snackbar>
  );

  const [reloadDataset, reloadResponse] = useReloadDatasetMutation();
  const [deleteDataset] = useDeleteSettingsMutation();

  const [selectedDatasetId, setSelectedDatasetId] = useState<string | null>(
    null
  );
  const [updatingDataset, setUpdatingDataset] = useState<string | null>(null);
  const datasetsData = useGetDatasetsQuery();

  const reloadHandler = useCallback(
    (id: string) =>
      reloadDataset(id)
        .unwrap()
        .then(() =>
          blinkSnackbar(intl.messages["successMessage"] as string, "success")
        )
        .catch(() =>
          blinkSnackbar(intl.messages["errorMessage"] as string, "error")
        ),
    [reloadDataset, blinkSnackbar, intl.messages]
  );

  const onAddButtonClick = useCallback(() => {
    navigate("/dataset");
  }, [navigate]);

  const deleteHandler = useCallback(
    (data: DatasetTablerowType) => {
      if (data?.id) {
        deleteDataset(data.id)
          .unwrap()
          .then(() =>
            blinkSnackbar(intl.messages["successMessage"] as string, "success")
          )
          .catch(() =>
            blinkSnackbar(intl.messages["errorMessage"] as string, "error")
          );
      }
    },
    [deleteDataset, blinkSnackbar, intl.messages]
  );

  const columns: ColumnDef<DatasetTablerowType>[] = useMemo(() => {
    let tableColumns: ColumnDef<DatasetTablerowType>[] = [
      {
        id: intl.messages["name"] as string,
        accessorFn: (row) => row.name,
        cell: (props) => {
          return (
            <Link
              underline="hover"
              component={RouterLink}
              to={{
                pathname: `/dataset/${props.row.original.id}`,
              }}
              sx={{ color: "inherit" }}
            >
              {props.row.original.name}
            </Link>
          );
        },
        size: BIG_SIZE_TO_PUSH_EDIT_COLUMN,
      },
      {
        id: intl.messages["runTime"] as string,
        accessorFn: (row) => row.lastUpdate.runTime,
        cell: (props) => {
          if (props.row.original.lastUpdate.runTime) {
            const rawDate = new Date(props.row.original.lastUpdate.runTime);
            const parsedDate = rawDate.toLocaleDateString(locale);
            const parsedTime = rawDate.toLocaleTimeString(locale);
            return (
              <span>
                {parsedDate} {parsedTime}
              </span>
            );
          }
          return <p>-</p>;
        },
        size: BIG_SIZE_TO_PUSH_EDIT_COLUMN,
      },
      {
        id: intl.messages["status"] as string,
        accessorFn: (row) => row.lastUpdate.status,
        cell: (props) => {
          switch (props.row.original.lastUpdate.status) {
            case "Loaded":
              return <span>{intl.messages["loaded"]}</span>;
            case "Waiting":
              return <span>{intl.messages["waitingForLoading"]}</span>;
            case "Error":
              return (
                <DbaTooltip
                  title={props.row.original.lastUpdate.message as string}
                >
                  <div>{intl.messages["error"]}</div>
                </DbaTooltip>
              );
            default:
              return null;
          }
        },
        size: BIG_SIZE_TO_PUSH_EDIT_COLUMN,
      },
      {
        id: intl.messages["update"] as string,
        cell: (props) => {
          switch (props.row.original.updateType) {
            case "Manual":
              return (
                <div
                  className={
                    reloadResponse.isLoading &&
                    updatingDataset === props.row.original.id
                      ? styles.rotate
                      : ""
                  }
                >
                  <DbaIconButton
                    size="small"
                    onClick={() => {
                      setUpdatingDataset(props.row.original.id);
                      reloadHandler(props.row.original.id);
                    }}
                    icon={<CachedIcon />}
                    disabled={
                      reloadResponse.isLoading &&
                      updatingDataset !== props.row.original.id
                    }
                  />
                </div>
              );
            case "Auto":
              return (
                <div
                  className={
                    reloadResponse.isLoading &&
                    updatingDataset === props.row.original.id
                      ? styles.rotate
                      : ""
                  }
                >
                  {props.row.original.schedule}
                  <DbaIconButton
                    size="small"
                    onClick={() => {
                      setUpdatingDataset(props.row.original.id);
                      reloadHandler(props.row.original.id);
                    }}
                    icon={<CachedIcon />}
                    disabled={
                      reloadResponse.isLoading &&
                      updatingDataset !== props.row.original.id
                    }
                  />
                </div>
              );
            default:
              return null;
          }
        },
        size: BIG_SIZE_TO_PUSH_EDIT_COLUMN,
      },
      {
        id: "show",
        header: "",
        cell: (props) => {
          return (
            <DbaIconButton
              size="small"
              onClick={() => setSelectedDatasetId(props.row.original.id)}
              icon={<VisibilityIcon sx={{ cursor: "pointer" }} />}
            />
          );
        },
        size: ACTION_COLUMN_SIZE,
      },
      {
        id: "ml",
        header: "",
        cell: (props) => (
          <DbaTooltip
            title="datasetPreprocessing"
            placement="left"
            arrow={true}
          >
            <Link
              component={RouterLink}
              to={{
                pathname: `/ml-dataset/${props.row.original.id}`,
              }}
            >
              <IconButton size="small">
                <DataThresholdingIcon />
              </IconButton>
            </Link>
          </DbaTooltip>
        ),
        size: ACTION_COLUMN_SIZE,
      },
      {
        id: "edition",
        header: "",
        cell: (props) => <EditButton editLink="/dataset/" row={props.row} />,
        size: ACTION_COLUMN_SIZE,
      },
      {
        id: "deletion",
        header: "",
        cell: (props) => (
          <DeleteButton row={props.row} deleteDataHandler={deleteHandler} />
        ),
        size: ACTION_COLUMN_SIZE,
      },
    ];
    if (!constants.isMLEnabled) {
      tableColumns = tableColumns.filter((item) => item.id !== "ml");
    }
    return tableColumns;
  }, [
    deleteHandler,
    intl.messages,
    reloadHandler,
    reloadResponse.isLoading,
    updatingDataset,
  ]);

  return (
    <CSSDataContainer>
      {datasetsData.isLoading ? (
        <LoadingScreen />
      ) : datasetsData.isError ? (
        <ErrorMessage />
      ) : (
        <>
          <DbaTable
            id="datasets-form-table"
            columns={columns}
            data={datasetsData.data ?? []}
            headToolbar={{ onAddButtonClick }}
            stylesSettings={{ autoColumnsWidth: false }}
          />
          {selectedDatasetId ? (
            <ViewData
              id={selectedDatasetId}
              setSelectedId={setSelectedDatasetId}
            />
          ) : null}
        </>
      )}
      {getSnackbar}
    </CSSDataContainer>
  );
};
