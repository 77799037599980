import { useContext, useRef, useMemo } from "react";
import { ThemeContext } from "../../utils/ThemeContext";
import { DbaErrorBoundary } from "../DbaErrorBoundary";
import { DbaAlert } from "../DbaAlert";
import Editor from "@monaco-editor/react";
import { useIntl } from "react-intl";
import CircularProgress from "@mui/material/CircularProgress";
import { DbaTable } from "../DbaTable/DbaTable";

export const DbaQueryCommandTable = ({ data, isFetching }: any) => {
  const { darkMode } = useContext(ThemeContext);
  const editorRef = useRef(null);
  const intl = useIntl();

  const tableData = data[0].data;

  const columns = useMemo(() => {
    if (!tableData.length) {
      return [];
    }
    const columnsKeys = Object.keys(tableData[0]);
    return columnsKeys.map((key) => ({
      id: key,
      header: key,
      accessorFn: (row: any) => row[key],
    }));
  }, [tableData]);

  function handleEditorDidMount(editor: any) {
    editorRef.current = editor;
    if (editorRef.current) {
      //@ts-ignore
      const messageContribution = editorRef.current.getContribution(
        "editor.contrib.messageController"
      );
      //@ts-ignore
      editorRef.current.onDidAttemptReadOnlyEdit(() => {
        messageContribution.showMessage(
          intl.messages["readOnly"],
          editor.getPosition()
        );
      });
    }
  }

  if (Array.isArray(tableData) && tableData.length === 0) {
    return <DbaAlert severity="error" text="noData" />;
  }
  if (!Array.isArray(tableData)) {
    return (
      <>
        <DbaAlert severity="error" text="tableDataTypeObjectError" />
        <Editor
          theme={darkMode ? "vs-dark" : "light"}
          height="50vh"
          defaultLanguage="json"
          value={JSON.stringify(tableData, null, "\t")}
          onMount={handleEditorDidMount}
          loading={<CircularProgress />}
          options={{
            scrollbar: {
              alwaysConsumeMouseWheel: false,
            },
            readOnly: true,
          }}
        />
      </>
    );
  }
  if (
    tableData.find((object: any) => {
      if (Object.values(object).find((value) => typeof value === "object")) {
        return object;
      }
      return false;
    })
  ) {
    return (
      <>
        <DbaAlert severity="error" text="tableDataFormatError" />
        <Editor
          theme={darkMode ? "vs-dark" : "light"}
          height="50vh"
          defaultLanguage="json"
          value={JSON.stringify(tableData, null, "\t")}
          onMount={handleEditorDidMount}
          loading={<CircularProgress />}
          options={{
            scrollbar: {
              alwaysConsumeMouseWheel: false,
            },
            readOnly: true,
          }}
        />
      </>
    );
  }

  return (
    <DbaErrorBoundary>
      <div
        className={darkMode ? "ag-theme-alpine-dark" : "ag-theme-alpine"}
        style={{
          width: "100%",
        }}
      >
        <DbaTable
          data={tableData}
          columns={columns}
          pagination={{
            enableServerPagination: false,
            showRowsPerPageSelector: true,
            autoResetPagination: false,
          }}
          isLoading={isFetching}
          headToolbar={{ showHeadToolbar: false }}
          stylesSettings={{
            cellStyles: {
              minWidth: "200px",
              padding: "10px",
              position: "relative",
              cursor: "default",
            },
          }}
          fixControls={{ enableFixControls: false }}
        />
      </div>
    </DbaErrorBoundary>
  );
};
