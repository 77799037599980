import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { BrowserRouter } from "react-router-dom";
import { ThemeProviderr } from "./utils/ThemeContext";
import { Provider } from "react-redux";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { store } from "./utils/store";
import { IntlProvider } from "react-intl";
import { messagesInRussian } from "./utils/locale";
import { disableReactDevTools } from "@fvilers/disable-react-devtools";

if (process.env.NODE_ENV === "production") {
  disableReactDevTools();
}

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Provider store={store}>
        <DndProvider backend={HTML5Backend}>
          <ThemeProviderr>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <IntlProvider
                messages={messagesInRussian}
                locale="ru"
                defaultLocale="ru"
              >
                <App />
              </IntlProvider>
            </LocalizationProvider>
          </ThemeProviderr>
        </DndProvider>
      </Provider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);
