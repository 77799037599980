import { useState, useReducer, useEffect } from "react";
import {
  SelectType,
  ExtendedPart,
  ExtendedParts,
  EAliasesDefaults,
} from "../../QueryConstructor.types";
import { DbaTextField, DbaButton, DbaAlert } from "../../../../DbaComponents";
import { QueryConstructorModal } from "../QueryConstructorModal";
import { ExtendedPartsActionTypes } from "./Select.types";
import { ExtendedSelect } from "./ExtendedSelect";
import { SelectPart } from "./SelectPart";
import { v4 as uuidv4 } from "uuid";
import { WidgetTypes } from "../../../../features/serviceSlices/Widget/Types";
import { useIntl } from "react-intl";

const isValid = (brackets: string) => {
  let stack: any = [];
  for (let bracket of brackets) {
    let char = stack[stack.length - 1];
    if (bracket === "(") {
      stack.push(bracket);
    } else if (char === "(" && bracket === ")") {
      stack.pop();
    } else return false;
  }
  return !stack.length;
};

const extendedPartsReducer = (
  state: ExtendedParts,
  action: ExtendedPartsActionTypes
) => {
  switch (action.type) {
    case "addExtendedPart":
      const newExtendedPartsArr = [...state];
      newExtendedPartsArr.push(action.payload);
      return newExtendedPartsArr;
    case "changeExtendedPart":
      const idx = state.findIndex((i) => i === action.initialObj);
      if (idx) {
        const newExtendedPartsArr = [...state];
        newExtendedPartsArr.splice(idx, 1, action.payload);
        return newExtendedPartsArr;
      }
      return state;
    case "deleteExtendedPart":
      return state.filter((i) => i.id !== action.payload.id);
    case "loadExtendedParts":
      return action.payload;
    case "columnChange":
      const objIdx = state.findIndex((i) => i.id === action.id);
      if (objIdx !== -1 && action.payload) {
        const newExtendedPartsArr = [...state];
        const newObj = { ...state[objIdx], column: action.payload.name };
        newExtendedPartsArr.splice(objIdx, 1, newObj);
        return newExtendedPartsArr;
      }
      return state;
    case "functionChange":
      const changedObjIdx = state.findIndex((i) => i.id === action.id);
      if (changedObjIdx !== -1) {
        const newExtendedPartsArr = [...state];
        const newObj = {
          ...state[changedObjIdx],
          function: action.payload ? action.payload.id : null,
        };
        newExtendedPartsArr.splice(changedObjIdx, 1, newObj);
        return newExtendedPartsArr;
      }
      return state;
    default:
      return state;
  }
};

export const SelectModal = ({
  tableName,
  dataSourceId,
  widgetType,
  open,
  setOpen,
  onSaveChanges,
  deleteHandler,
  data,
}: {
  data: SelectType;
  onSaveChanges: (arg: SelectType, initialState: SelectType) => void;
  deleteHandler: (arg: SelectType) => void;
  tableName: string;
  dataSourceId: string;
  widgetType: WidgetTypes;
  open: boolean;
  setOpen: (arg: boolean) => void;
}) => {
  const intl = useIntl();

  const [alias, setAlias] = useState<EAliasesDefaults | string>(data.alias);
  const [extendedParts, dispatchExtendedParts] = useReducer(
    extendedPartsReducer,
    data.parts
  );
  const [isExtended, setIsExtended] = useState(data.isExtended);
  const [error, setError] = useState(false);
  const [invalidBrackets, setInvalidBrackets] = useState(false);

  const onSaveExpressionHandler = () => {
    if (!isExtended) {
      if (
        (widgetType === "Default" &&
          (extendedParts[0].function === "" ||
            extendedParts[0].function === null)) ||
        extendedParts[0].column === null ||
        extendedParts[0].column === "" ||
        alias.trim() === ""
      ) {
        setError(true);
        return;
      }
    }
    if (isExtended) {
      const unfilledColumn = extendedParts.find(
        (part) => part.column === null && part.type === "Field"
      );
      if (unfilledColumn) {
        setError(true);
        return;
      }
      const brackets = extendedParts.filter(
        (object) =>
          object.operand === "OpenBracket" || object.operand === "CloseBracket"
      );
      if (brackets) {
        const stringifiedBrackets = brackets
          .map((bracketObj) =>
            bracketObj.operand === "OpenBracket" ? "(" : ")"
          )
          .join("");
        if (!isValid(stringifiedBrackets)) {
          setError(true);
          setInvalidBrackets(true);
          return;
        }
      }
    }
    onSaveChanges(
      {
        alias,
        parts: extendedParts,
        isExtended,
      },
      data
    );
    setOpen(false);
  };

  const onDeleteOrderHandler = () => {
    deleteHandler(data);
    setOpen(false);
  };

  useEffect(() => {
    if (invalidBrackets) {
      if (isExtended) {
        const brackets = extendedParts.filter(
          (object) =>
            object.operand === "OpenBracket" ||
            object.operand === "CloseBracket"
        );
        if (brackets) {
          const stringifiedBrackets = brackets
            .map((bracketObj) =>
              bracketObj.operand === "OpenBracket" ? "(" : ")"
            )
            .join("");
          if (!isValid(stringifiedBrackets)) {
            setInvalidBrackets(true);
            return;
          } else {
            setInvalidBrackets(false);
          }
        }
      }
    }
  }, [extendedParts, invalidBrackets, isExtended]);

  useEffect(() => {
    const widgetTypesToSetAlias = ["Diagram", "Table", undefined];
    if (
      widgetTypesToSetAlias.includes(widgetType) &&
      !isExtended &&
      extendedParts[0].column
    ) {
      if (
        Object.values(EAliasesDefaults).includes(data.alias as EAliasesDefaults)
      ) {
        setAlias(extendedParts[0].column);
      }
    }
  }, [data.alias, extendedParts, isExtended, widgetType]);

  return (
    <QueryConstructorModal
      open={open}
      title={intl.messages["columns"] as string}
      saveHandler={onSaveExpressionHandler}
      deleteHandler={onDeleteOrderHandler}
      fullWidth={isExtended}
    >
      <DbaButton
        variant="text"
        text={isExtended ? "regularMode" : "extendedMode"}
        sx={{
          textDecoration: "underline",
        }}
        onClick={() => {
          dispatchExtendedParts({
            type: "loadExtendedParts",
            payload: isExtended
              ? [
                  {
                    column: "",
                    function: "",
                    type: "Field",
                    operand: null,
                    id: uuidv4(),
                  },
                ]
              : [],
          });
          setIsExtended((prevState) => !prevState);
          setError(false);
        }}
      />
      {isExtended ? (
        <ExtendedSelect
          data={extendedParts}
          widgetType={widgetType}
          onAddOperandHandler={(value: ExtendedPart) =>
            dispatchExtendedParts({
              type: "addExtendedPart",
              payload: value,
            })
          }
          onDeleteOperandHandler={(value: ExtendedPart) =>
            dispatchExtendedParts({
              type: "deleteExtendedPart",
              payload: value,
            })
          }
          tableName={tableName}
          dataSourceId={dataSourceId}
          dispatchExtendedParts={dispatchExtendedParts}
          error={error}
        />
      ) : (
        extendedParts.map((part) => (
          <SelectPart
            key={part.id}
            tableName={tableName}
            dataSourceId={dataSourceId}
            data={part}
            widgetType={widgetType}
            error={error}
            onColumnChange={(value: any) =>
              dispatchExtendedParts({
                type: "columnChange",
                payload: value,
                id: part.id,
              })
            }
            onFunctionChange={(value: any) =>
              dispatchExtendedParts({
                type: "functionChange",
                payload: value,
                id: part.id,
              })
            }
          />
        ))
      )}

      <DbaTextField
        required
        size="small"
        label="alias"
        value={alias}
        setValue={setAlias}
        error={alias.trim() === ""}
        helperText="fieldIsEmptyError"
        disabled={widgetType === "Default" || widgetType === "Filter"}
      />
      {invalidBrackets && <DbaAlert text="bracketsError" severity="error" />}
    </QueryConstructorModal>
  );
};
