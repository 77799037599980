import React, { useMemo } from "react";
import { TTableWidgetProps } from "./TableWidget.types";
import { WidgetLayout } from "..";
import { DbaAlert, DbaTable } from "../../../../DbaComponents";
import { CSSScrollContainer } from "./TableWidget.styles";
import colors from "../../../../Variables.module.scss";
import { ColumnDef } from "@tanstack/react-table";
import { convertISODatesInCollectionToLocalStringByTimezonesShift } from "../../../../utils/helpers/dateTime";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";

export const TableWidget: React.FC<TTableWidgetProps> = ({
  id,
  data,
  appTimezone,
  editMode,
  selectedElement,
  setSelectedElement,
  onSortingChange,
  showModalLoadingIndicator,
  onColumnSortingChange,
  tableFilters,
  onTableFiltersChange,
}) => {
  const isThresholdFontColor =
    data.widgetSettings.settings.widget?.colorScheme === "thresholds";

  const getShowedColor = () => {
    if (
      isThresholdFontColor &&
      data.widgetSettings.settings.widget?.thresholds
    ) {
      return data.widgetSettings.settings.widget.tresholdBaseColor ?? "#FF0000";
    }
    return data.widgetSettings.settings?.widget?.fontColor;
  };

  const showedFontColor = getShowedColor();

  const columns: ColumnDef<any>[] = useMemo(() => {
    return (
      data?.headers
        ?.map((header) => {
          return {
            id: header.field,
            header: header.headerName,
            accessorFn: (row: any) => row[header.field],
            order: header.order,
            meta: {
              dataType: header.type,
            },
            cell: (props: any) => {
              const linkTypes = ["Image", "File", "Video"];
              if (linkTypes.includes(header.type)) {
                return (
                  <a
                    target="_blank"
                    href={props.row.original[header.field]}
                    rel="noopener noreferrer"
                  >
                    <OpenInNewIcon />
                  </a>
                );
              }
              return <span>{props.row.original[header.field]}</span>;
            },
          };
        })
        .sort((a, b) => a.order - b.order) ?? []
    );
  }, [data?.headers]);

  const tableData = useMemo(() => {
    return data
      ? convertISODatesInCollectionToLocalStringByTimezonesShift({
          collection: data?.data[0]?.data ?? [],
          headers: data?.headers ?? [],
          originalTZ: data?.timeZoneId,
          targetTZ: appTimezone,
        })
      : [];
  }, [appTimezone, data]);

  let content;

  if (!columns.length) {
    content = <DbaAlert severity="warning" text="noReferenceHeaders" />;
  } else if (!tableData.length && !tableFilters?.length) {
    content = <DbaAlert severity="warning" text="emptyData" />;
  } else {
    content = (
      <CSSScrollContainer>
        <DbaTable
          id={id}
          columns={columns}
          data={tableData}
          stylesSettings={{
            backgroundColorDarkThemeOverride: colors.purple900,
          }}
          pagination={{ showPagination: false }}
          headToolbar={{ showHeadToolbar: false }}
          sorting={{
            enableServerSorting: !!onSortingChange,
            onSortingChange: onSortingChange,
          }}
          columnSorting={{
            onColumnSortingChange,
          }}
          filters={{
            enableFilters: true,
            filtersList: tableFilters,
            onFiltersListChange: onTableFiltersChange,
          }}
        />
      </CSSScrollContainer>
    );
  }

  return (
    <WidgetLayout
      settings={data.widgetSettings.settings.widget}
      label={data.label}
      data={data}
      type="Table"
      editMode={editMode}
      setSelectedElement={setSelectedElement}
      selectedElement={selectedElement}
      settingsId={data.widgetSettings.id}
      dashboardId={data.widgetSettings.dashboardID}
      widgetID={data.widgetSettings.widgetID}
      fontColor={showedFontColor}
      thresholdFontColor={isThresholdFontColor}
      fetching={showModalLoadingIndicator}
    >
      {content}
    </WidgetLayout>
  );
};
