import React, { useState } from "react";
import { useIntl } from "react-intl";
import Menu from "@mui/material/Menu";
import Fade from "@mui/material/Fade";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import SettingsIcon from "@mui/icons-material/Settings";
import { WidgetMenuProps } from "./WidgetMenu.types";
import { WidgetRelatedReportType } from "../../../../features/serviceSlices/Widget/Types";
import { useNavigate, useLocation, useParams } from "react-router";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import InfoIcon from "@mui/icons-material/Info";
import { useApplySettingsMutation } from "../../../../features/serviceSlices/serviceHooks";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import PaletteIcon from "@mui/icons-material/Palette";
import LinkIcon from "@mui/icons-material/Link";
import SummarizeIcon from "@mui/icons-material/Summarize";
import Popover from "@mui/material/Popover";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ListItem from "@mui/material/ListItem";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

export const WidgetMenu = ({
  settingsId,
  dashboardId,
  widgetID,
  type,
  openWidgetInfoModal,
  handleSetActiveRelatedReport,
  sx,
  editMode,
  widgetLinks,
  widgetRelatedReports,
  isError,
}: WidgetMenuProps) => {
  const [applySettings] = useApplySettingsMutation();
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const intl = useIntl();

  const [anchorMenuEl, setAnchorMenuEl] = useState<HTMLElement | null>(null);
  const [linksAnchorEl, setLinksAnchorEl] = useState<HTMLButtonElement | null>(
    null
  );
  const [reportsAnchorEl, setReportsAnchorEl] =
    useState<HTMLButtonElement | null>(null);

  const openMenu = Boolean(anchorMenuEl);
  const openLinksMenu = Boolean(linksAnchorEl);
  const openReportsMenu = Boolean(reportsAnchorEl);

  const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorMenuEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorMenuEl(null);
  };

  const handleOpenLinksMenu = (event: React.BaseSyntheticEvent) => {
    setLinksAnchorEl(event.currentTarget);
  };

  const handleCloseLinksMenu = () => {
    setLinksAnchorEl(null);
  };

  const handleOpenReportsMenu = (event: React.BaseSyntheticEvent) => {
    setReportsAnchorEl(event.currentTarget);
  };

  const handleCloseReportsMenu = () => {
    setReportsAnchorEl(null);
  };

  const applySettingsHandler = () =>
    applySettings({ widgetID: widgetID!, dashboardID: dashboardId! });

  const fullScreenModeHandler = () =>
    navigate(
      `/${dashboardId}/widget?id=${widgetID}&mode=full&widgetType=${type}`
    );

  const widgetVisualSettingsHandler = () =>
    navigate(`/widgetEditor/${settingsId}`);

  const editWidgetHandler = () =>
    navigate(`/widget/${widgetID}?widgetType=${type}`);

  const infoModalHandler = () => {
    handleCloseMenu();
    openWidgetInfoModal && openWidgetInfoModal();
  };

  const widgetRelatedReportsModalHandler =
    (report: WidgetRelatedReportType) => () => {
      if (handleSetActiveRelatedReport) {
        handleSetActiveRelatedReport(report);
        handleCloseMenu();
        handleCloseReportsMenu();
      }
    };

  const widgetLinksModalHandler = () => {
    handleCloseMenu();
    handleCloseLinksMenu();
  };

  if (
    (location.pathname !== "/" && !params?.dashboardID) ||
    (!editMode && type === "Filter") ||
    location.search !== ""
  ) {
    return null;
  }
  if (
    (type === "Default" ||
      type === "Table" ||
      type === "Video" ||
      type === "Text") &&
    !editMode &&
    !(widgetLinks || widgetRelatedReports)
  ) {
    return null;
  }

  //если у виджета ошибка, то показываем меню только в режиме редактирования
  if (isError && !editMode) {
    return null;
  }
  return (
    <>
      <IconButton
        onClick={handleOpenMenu}
        sx={{
          position: "absolute",
          top: "-7px",
          right: "-22px",
          opacity: openMenu ? 1 : 0,
          ...sx,
        }}
      >
        <MoreVertIcon />
      </IconButton>

      <Menu
        anchorEl={anchorMenuEl}
        open={openMenu}
        onClose={handleCloseMenu}
        TransitionComponent={Fade}
      >
        {editMode && type !== "Video" && !!settingsId ? (
          <MenuItem onClick={widgetVisualSettingsHandler}>
            <ListItemIcon>
              <PaletteIcon />
            </ListItemIcon>
            <ListItemText primary={intl.messages["visualSettings"]} />
          </MenuItem>
        ) : null}
        {editMode && (
          <MenuItem onClick={editWidgetHandler}>
            <ListItemIcon>
              <SettingsIcon />
            </ListItemIcon>
            <ListItemText primary={intl.messages["data"]} />
          </MenuItem>
        )}

        {!isError && editMode && type !== "Filter" && (
          <MenuItem onClick={infoModalHandler}>
            <ListItemIcon>
              <InfoIcon />
            </ListItemIcon>
            <ListItemText primary={intl.messages["info"]} />
          </MenuItem>
        )}

        {!isError &&
          dashboardId &&
          widgetID &&
          (type === "Diagram" || type === "Map" || type === "Image") && (
            <MenuItem onClick={fullScreenModeHandler}>
              <ListItemIcon>
                <FullscreenIcon />
              </ListItemIcon>
              <ListItemText primary={intl.messages["fullScreenMode"]} />
            </MenuItem>
          )}

        {!isError && editMode && (
          <MenuItem onClick={applySettingsHandler}>
            <ListItemIcon>
              <ContentCopyIcon />
            </ListItemIcon>
            <ListItemText primary={intl.messages["applyToAll"]} />
          </MenuItem>
        )}

        {widgetLinks && (
          <MenuItem
            sx={{ minWidth: "170px" }}
            onClick={(e) => handleOpenLinksMenu(e)}
          >
            <ListItemIcon>
              <LinkIcon />
            </ListItemIcon>
            <ListItemText primary={intl.messages["links"]} />
            {linksAnchorEl ? (
              <ArrowBackIosIcon fontSize="small" />
            ) : (
              <ArrowForwardIosIcon fontSize="small" />
            )}
          </MenuItem>
        )}
        {widgetRelatedReports && (
          <MenuItem
            sx={{ minWidth: "170px" }}
            onClick={(e) => handleOpenReportsMenu(e)}
          >
            <ListItemIcon>
              <SummarizeIcon />
            </ListItemIcon>
            <ListItemText primary={intl.messages["reports"]} />
            {reportsAnchorEl ? (
              <ArrowBackIosIcon fontSize="small" />
            ) : (
              <ArrowForwardIosIcon fontSize="small" />
            )}
          </MenuItem>
        )}
      </Menu>
      {widgetLinks && (
        <Popover
          open={openLinksMenu}
          anchorEl={linksAnchorEl}
          onClose={handleCloseLinksMenu}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <List>
            {widgetLinks.length === 0
              ? intl.messages["noLinks"]
              : widgetLinks.map((item, index) => (
                  <ListItem key={index} disablePadding>
                    <ListItemButton
                      key={index}
                      component="a"
                      href={item.url}
                      target={item.isNewTab ? "_blank" : "_self"}
                      onClick={widgetLinksModalHandler}
                    >
                      <ListItemText primary={item.title} />
                    </ListItemButton>
                  </ListItem>
                ))}
          </List>
        </Popover>
      )}
      {widgetRelatedReports && (
        <Popover
          open={openReportsMenu}
          anchorEl={reportsAnchorEl}
          onClose={handleCloseReportsMenu}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <List>
            {widgetRelatedReports.length === 0
              ? intl.messages["noReports"]
              : widgetRelatedReports.map((item) => (
                  <ListItem key={item.id} disablePadding>
                    <ListItemButton
                      key={item.id}
                      component="div"
                      onClick={widgetRelatedReportsModalHandler(item)}
                    >
                      <ListItemText primary={item.name} />
                    </ListItemButton>
                  </ListItem>
                ))}
          </List>
        </Popover>
      )}
    </>
  );
};
