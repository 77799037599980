import { IntlShape } from "react-intl";
import { TDataWidgetData } from "../../../../features/serviceSlices/SpecificWidgets/DataWidget/Types";
import { roundNumber } from "../../../../utils/helpers/functions";

const getDifferenceValue = (data: TDataWidgetData) => {
  if (
    data?.prevValue === null ||
    data?.value === null ||
    !Boolean(data?.value?.[0]?.data?.[0]?.value)
  ) {
    return "";
  }

  if (data.prevValue[0].data === null) return "";

  if (data.prevValue[0].data.length === 0) return "";

  const rawDifferenceValue =
    data.value[0].data[0].value - data.prevValue[0].data[0].value;

  return data.roundToNumber >= 0
    ? roundNumber(rawDifferenceValue, data.roundToNumber)
    : rawDifferenceValue;
};

const getValue = (
  rawValue: number | string | null | undefined,
  data: TDataWidgetData,
  intl: IntlShape
) => {
  if (rawValue === undefined || rawValue === null) {
    if (data.emptyValueBehavior === "NoData") {
      return intl.messages["noData"] as string;
    }
    if (data.emptyValueBehavior === "ShowZero") {
      return 0;
    }
    //showNothing
    return "";
  } else {
    const numberValue = Number(rawValue);
    if (!Number.isNaN(numberValue)) {
      return data.roundToNumber >= 0
        ? roundNumber(rawValue as number, data.roundToNumber)
        : rawValue;
    } else {
      throw new Error(intl.messages["notANumber"] as string);
    }
  }
};

export { getDifferenceValue, getValue };
