import { useContext, useState } from "react";
import {
  TextWidgetConstructorProps,
  WidgetElementTypes,
} from "./TextWidgetConstructor.types";
import { WidgetConstructorToolbar } from "../WidgetConstructorToolbar";
import { DbaErrorBoundary } from "../../../../DbaComponents";
import {
  CSSConstructorContainer,
  CSSEditableWidgetContainer,
} from "./TextWidgetConstructor.styles";
import {
  TextWidget,
  WidgetContainer,
} from "../../../../components/Widgets/common";
import { ThemeContext } from "../../../../utils/ThemeContext";

export const TextWidgetConstructor = ({
  settings,
  setSettings,
  widgetData,
}: TextWidgetConstructorProps) => {
  const { darkMode } = useContext(ThemeContext);
  const [selectedElement, setSelectedElement] =
    useState<WidgetElementTypes | null>("body");

  return (
    <CSSConstructorContainer>
      <WidgetConstructorToolbar
        widgetType="Text"
        settings={settings}
        setSettings={setSettings}
        selectedElement={selectedElement}
        setSelectedElement={setSelectedElement}
      />
      <CSSEditableWidgetContainer>
        <DbaErrorBoundary>
          <WidgetContainer
            type="Text"
            darkMode={darkMode}
            body={settings.widget.body}
          >
            <TextWidget
              data={{
                description: widgetData.description,
                id: widgetData.id,
                label: widgetData.label,
                name: widgetData.name,
                widgetSettings: {
                  settings,
                },
                widgetType: "Text",
                widgetLinks: [],
                widgetReports: [],
              }}
            />
          </WidgetContainer>
        </DbaErrorBoundary>
      </CSSEditableWidgetContainer>
    </CSSConstructorContainer>
  );
};
