import React from "react";
import { useIntl } from "react-intl";
import { DbaModal } from "../../../../../../DbaComponents";
import { MapObjectInfoModalContent } from "./MapObjectInfoModalContent";
import { TMapObjectInfoModal } from "./Types";

export const MapObjectInfoModal: React.FC<TMapObjectInfoModal> = ({
  open,
  setOpen,
  isModalVertical,
  appTimezone,
  dashboardId,
  mapObjectId,
  featureObjectId,
  filters,
}) => {
  const intl = useIntl();

  return (
    <DbaModal
      open={open}
      setOpen={setOpen}
      fullWidth={!isModalVertical}
      title={intl.messages["mapObjectInfo"] as string}
    >
      <div
        style={
          isModalVertical
            ? {
                minHeight: "300px",
              }
            : {
                height: "500px",
                width: "100%",
              }
        }
      >
        <MapObjectInfoModalContent
          isModalVertical={isModalVertical}
          dashboardId={dashboardId}
          mapObjectId={mapObjectId}
          featureObjectId={featureObjectId}
          appTimezone={appTimezone}
          filters={filters}
        />
      </div>
    </DbaModal>
  );
};
