import { useState, useEffect, useLayoutEffect } from "react";
import { useParams, useNavigate } from "react-router";
import {
  useLazyGetDataSourceQuery,
  useUpdateDataSourceMutation,
  useCreateDataSourceMutation,
  useGetProvidersQuery,
  useLazyTestConnectionQuery,
} from "../../features/serviceSlices/serviceHooks";
import { LoadingScreen, ErrorMessage } from "../../components/common";
import {
  DbaTextField,
  DbaDataFetchSelect,
  DbaInputsGenerator,
  DbaButton,
  DbaSnackbar,
  DbaTimezoneSelector,
} from "../../DbaComponents";
import { CSSDataSourceContainer } from "./StyledComponents";
import { Stack } from "@mui/material";
import {
  Provider,
  ProviderSettingsField,
} from "../../features/serviceSlices/Providers/Types";
import { SaveButton } from "../../components/common";
import {
  findUnfilledInput,
  cleanSettings,
} from "../../utils/helpers/functions";
import { FetchBaseQueryErrorType } from "../../features/serviceSlices/SharedTypes";
import { TTimezone } from "../../features/serviceSlices/Timezones/Types";
import { DbaConfirmDialog } from "../../DbaComponents/DbaConfirmDialog";

export const DataSource = () => {
  const navigate = useNavigate();
  const params = useParams<Record<string, string | undefined>>();
  const editMode = Object.keys(params).length > 0;
  const [getDataSource, dataSource] = useLazyGetDataSourceQuery();
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [timezone, setTimezone] = useState<TTimezone | string>();
  const [pluginType, setPluginType] = useState<Provider | null>(null);
  const [settings, setSettings] = useState("{}");
  const [fields, setFields] = useState<ProviderSettingsField[] | null>(null);
  const [error, setError] = useState(false);
  const [customError, setCustomError] = useState("");
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] =
    useState<boolean>(false);
  const providers = useGetProvidersQuery();

  const [createDataSource, createDataSourceResponse] =
    useCreateDataSourceMutation();
  const [updateDataSource, updateDataSourceResponse] =
    useUpdateDataSourceMutation();

  const [testConnection, testConnectionResponse] = useLazyTestConnectionQuery();

  const onSubmitFormHandler = () => {
    if (
      editMode
        ? updateDataSourceResponse.status === "pending" ||
          updateDataSourceResponse.status === "fulfilled"
        : createDataSourceResponse.status === "pending" ||
          createDataSourceResponse.status === "fulfilled"
    )
      return;
    if (
      !name.trim() ||
      pluginType === null ||
      Boolean(findUnfilledInput(settings, pluginType.sourceSettingsField)) ||
      !timezone
    ) {
      setError(true);
    } else {
      setError(false);
      const dataSourceObject = {
        name: name.trim(),
        description: description.trim(),
        timeZoneId: typeof timezone === "string" ? timezone : timezone?.id!,
        settings: cleanSettings(settings, pluginType.sourceSettingsField),
        pluginType: pluginType.name,
      };
      editMode
        ? updateDataSource({ ...dataSourceObject, id: params.id })
        : createDataSource(dataSourceObject);
    }
  };

  const onConfirmCancelClick = () => {
    navigate(-1);
  };

  const getCheckConnectionButton = () => {
    if (
      providers.data !== undefined &&
      pluginType !== null &&
      providers.data.find((provider) => provider.name === pluginType.name)
        ?.isCanCheckConnection
    ) {
      return (
        <DbaButton
          isLoading={testConnectionResponse.isLoading}
          variant="outlined"
          onClick={() => {
            if (pluginType && pluginType.name) {
              testConnection({
                name: pluginType.name,
                sourceSettings: settings,
              });
            }
          }}
          text="testConnection"
        />
      );
    } else {
      return null;
    }
  };

  useLayoutEffect(() => {
    if (editMode) {
      getDataSource(params.id!);
    }
  }, [editMode, params.id, getDataSource]);

  useEffect(() => {
    if (dataSource.data) {
      setName(dataSource.data.name);
      setDescription(dataSource.data.description);
      setTimezone(dataSource.data.timeZoneId);
      setSettings(dataSource.data.settings);
    }
  }, [dataSource.data]);

  useEffect(() => {
    if (pluginType === null && providers.data) {
      setPluginType(providers.data[0]);
    }
    if (pluginType && providers.data) {
      const obj = providers.data.find(
        (provider) => provider.name === pluginType.name
      );
      if (obj) {
        setFields(obj.sourceSettingsField);
      }
    }
  }, [pluginType, providers.data]);

  const isFetchBaseQueryErrorType = (
    error: any
  ): error is FetchBaseQueryErrorType => "status" in error;

  useEffect(() => {
    if (testConnectionResponse.error) {
      if (isFetchBaseQueryErrorType(testConnectionResponse.error)) {
        setCustomError(testConnectionResponse.error.data.message);
      }
    }
  }, [testConnectionResponse.error]);

  const confirmCancelModal = (
    <DbaConfirmDialog
      isOpen={isConfirmDialogOpen}
      title="cancelConfirmTitle"
      message="cancelConfirmMessage"
      onAccept={onConfirmCancelClick}
      onDecline={() => setIsConfirmDialogOpen(false)}
    />
  );

  return editMode && (dataSource.isLoading || providers.isLoading) ? (
    <LoadingScreen />
  ) : (editMode && dataSource.isError) || providers.isError ? (
    <ErrorMessage />
  ) : (
    <CSSDataSourceContainer autoComplete="off">
      <Stack direction="row" spacing={2}>
        <DbaButton
          color="error"
          onClick={() => setIsConfirmDialogOpen(true)}
          text="cancel"
        />
        <SaveButton
          redirectUrl="/datasources"
          onClick={onSubmitFormHandler}
          status={
            editMode
              ? updateDataSourceResponse.status
              : createDataSourceResponse.status
          }
        />
        {getCheckConnectionButton()}
      </Stack>
      <DbaTextField
        required
        error={error && !name.trim()}
        value={name}
        setValue={setName}
        label="name"
        helperText="fieldIsEmptyError"
      />
      <DbaTextField
        value={description}
        setValue={setDescription}
        label="description"
      />
      <DbaTimezoneSelector
        value={timezone}
        onChange={(timezone) => setTimezone(timezone)}
        required
        error={error && !timezone}
      />
      {editMode && dataSource.data ? (
        <DbaDataFetchSelect
          required
          error={error && pluginType === null}
          url="api/Provider/GetProviders"
          selectedValue={pluginType}
          setSelectedValue={setPluginType}
          label="plugin"
          labelIndex="name"
          keyIndex={dataSource.data.pluginType}
        />
      ) : (
        <DbaDataFetchSelect
          required
          error={error && pluginType === null}
          url="api/Provider/GetProviders"
          selectedValue={pluginType}
          setSelectedValue={setPluginType}
          label="plugin"
        />
      )}

      {pluginType && fields && (
        <DbaInputsGenerator
          fields={fields}
          state={settings}
          setState={setSettings}
          error={error}
        />
      )}

      <DbaSnackbar
        duration={10000}
        errorMessage={customError}
        successMessage="success"
        error={testConnectionResponse.isError}
        success={testConnectionResponse.isSuccess}
      />
      {confirmCancelModal}
    </CSSDataSourceContainer>
  );
};
