import React, { useState, useEffect, ChangeEvent } from "react";
import Box from "@mui/material/Box";
import {
  DbaTextField,
  DbaButton,
  DbaDataFetchSelect,
  DbaIconButton,
  DbaFileUploader,
  DbaSnackbar,
} from "../../DbaComponents";
import { Stack } from "@mui/material";
import { useNavigate, useParams } from "react-router";
import {
  useUploadFileMutation,
  useUpdateFileMutation,
  useLazyGetFileQuery,
} from "../../features/serviceSlices/serviceHooks";
import {
  SaveButton,
  LoadingScreen,
  ErrorMessage,
} from "../../components/common";
import { useIntl } from "react-intl";
import {
  CSSFieldsContainer,
  CSSLabel,
  CSSInputBlock,
  CSSSelectCategoryContainer,
} from "./Files.styles";
import ClearIcon from "@mui/icons-material/Clear";
import { TFileCategory } from "../../features/serviceSlices/FileCategories/Types";
import {
  TFileUploadRequest,
  TFileUpdateRequest,
} from "../../features/serviceSlices/Files/Types";
import constants from "../../utils/constants";
import { getErrorMessage } from "../../utils/helpers/functions";
import { DbaConfirmDialog } from "../../DbaComponents/DbaConfirmDialog";

export const File = () => {
  const params = useParams<Record<string, string | undefined>>();
  const navigate = useNavigate();
  const editMode = Boolean(params.id);
  const intl = useIntl();

  const [name, setName] = useState<string>("");
  const [category, setCategory] = useState<TFileCategory | null>(null);
  const [file, setFile] = useState<File | null>(null);

  const [getFileData, fileData] = useLazyGetFileQuery();
  const [addFile, response] = useUploadFileMutation();
  const [updateFile, updateResponse] = useUpdateFileMutation();

  const [error, setError] = useState<boolean>(false);
  const [uploaderError, setUploaderError] = useState<boolean>(false);
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] =
    useState<boolean>(false);

  const errorMessage = getErrorMessage(
    response.isError ? response : updateResponse
  );

  const onSubmitFileFormHandler = (e: React.FormEvent) => {
    e.preventDefault();
    if (
      editMode
        ? updateResponse.status === "pending" ||
          updateResponse.status === "fulfilled"
        : response.status === "pending" || response.status === "fulfilled"
    ) {
      return;
    }
    if (!name.trim() || (!editMode && !file)) {
      setError(true);
      return;
    }
    setError(false);
    if (uploaderError) {
      return;
    }
    const fileWithMetaData: TFileUploadRequest | TFileUpdateRequest = {
      id: params?.id!,
      name: name.trim(),
      categoryId: category?.id,
    };
    if (!editMode) {
      fileWithMetaData.file = file;
    } else {
      fileWithMetaData.file = file;
    }
    editMode
      ? updateFile(fileWithMetaData)
      : addFile(fileWithMetaData as TFileUploadRequest);
  };

  const onFileChange = (e: ChangeEvent<HTMLInputElement>, error?: string) => {
    setFile(e.target.files ? e.target.files[0] : null);
    setUploaderError(!!error);
  };

  const onCancelClick = () => setIsConfirmDialogOpen(true);

  const onConfirmCancelClick = () => {
    navigate(-1);
  };

  const onClearCategoryClick = () => setCategory(null);

  useEffect(() => {
    if (editMode) {
      getFileData(params.id!);
    }
  }, [editMode, getFileData, params.id]);

  useEffect(() => {
    if (fileData.data) {
      setName(fileData.data.name);
    }
  }, [fileData.data]);

  if (editMode && fileData.isError) {
    return <ErrorMessage />;
  }

  if (editMode && fileData.isLoading) {
    return <LoadingScreen />;
  }

  const confirmCancelModal = (
    <DbaConfirmDialog
      isOpen={isConfirmDialogOpen}
      title="cancelConfirmTitle"
      message="cancelConfirmMessage"
      onAccept={onConfirmCancelClick}
      onDecline={() => setIsConfirmDialogOpen(false)}
    />
  );

  const isEditing = response.isLoading || updateResponse.isLoading;

  return !editMode || (editMode && fileData.isSuccess) ? (
    <Box component="form" onSubmit={onSubmitFileFormHandler} noValidate>
      <Stack direction="row" spacing={2} sx={{ mb: "1rem" }}>
        <DbaButton
          onClick={onCancelClick}
          color="error"
          variant="contained"
          text="cancel"
          disabled={isEditing}
        />
        <SaveButton
          type="submit"
          redirectUrl="/files"
          status={editMode ? updateResponse.status : response.status}
        />
      </Stack>
      <Stack direction="column" spacing={2}>
        <CSSInputBlock>
          <CSSLabel>{intl.messages["mainFields"]}:</CSSLabel>
          <CSSFieldsContainer>
            <DbaTextField
              required
              error={error && !name.trim()}
              value={name}
              setValue={setName}
              label="name"
              disabled={isEditing}
              helperText="fieldIsEmptyError"
            />
          </CSSFieldsContainer>
        </CSSInputBlock>
        <CSSSelectCategoryContainer>
          <DbaDataFetchSelect
            url="api/category/getall"
            label="fileCategories"
            labelIndex="id"
            keyIndex={fileData?.data?.categoryId}
            selectedValue={category}
            setSelectedValue={setCategory}
            disabled={isEditing}
          />
          <DbaIconButton icon={<ClearIcon />} onClick={onClearCategoryClick} />
        </CSSSelectCategoryContainer>
        <DbaFileUploader
          file={file || fileData?.data}
          maxSize={constants.maxFileSize}
          onChange={onFileChange}
          isOuterError={!editMode && error && !file}
          outerErrorText={
            !editMode && error && !file
              ? (intl.messages["requiredField"] as string)
              : ""
          }
          isLoading={isEditing}
        />
      </Stack>
      <DbaSnackbar
        successMessage={
          updateResponse.isSuccess ? "editSuccesResponse" : "fileSuccessUpload"
        }
        errorMessage={errorMessage}
        error={updateResponse.isError || response.isError}
        success={updateResponse.isSuccess || response.isSuccess}
      />
      {confirmCancelModal}
    </Box>
  ) : null;
};
