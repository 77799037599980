import { useContext, useReducer, useEffect } from "react";
import { DbaIconButton } from "../../DbaComponents";
import AddIcon from "@mui/icons-material/Add";
import colors from "../../Variables.module.scss";
import { ThemeContext } from "../../utils/ThemeContext";
import {
  FiltersConstructorProps,
  FiltersType,
  FiltersActionType,
} from "./FiltersConstructor.types";
import {
  CSSQueryConstructorContainer,
  CSSQueryConstructorRow,
  CSSQueryConstructorRowLabel,
} from "../QueryConstructor/QueryConstructor.styles";
import { useIntl } from "react-intl";
import { Filter } from "./Filter";
import { FilterType } from "./FiltersConstructor.types";
import Typography from "@mui/material/Typography";
import { ErrorMessage } from "../common";
import Alert from "@mui/material/Alert";

const filtersReducer = (
  state: FiltersType | null,
  action: FiltersActionType
) => {
  switch (action.type) {
    case "addFilter":
      const newFilter: Record<keyof FilterType, string> = {
        field: "",
        condition: "",
        variable: "",
        type: "",
      };
      if (state) {
        const newFiltersArr = [...state];
        newFiltersArr.push(newFilter);
        return newFiltersArr;
      }
      return [newFilter];
    case "changeFilter":
      if (state) {
        const idx = state.findIndex((i) => i === action.initialObj);
        const newFiltersArr = [...state];
        newFiltersArr.splice(idx, 1, action.payload);
        return newFiltersArr;
      }
      return state;
    case "deleteFilter":
      if (state) {
        return state.filter((i) => i !== action.payload);
      }
      return state;
    case "loadFilters":
      return action.payload;
    default:
      return state;
  }
};

const initialFiltersState: FiltersType | null = null;

export const FiltersConstructor = ({
  data,
  setParsedData,
  tableName,
  dataSourceId,
  isManualTextControl,
}: FiltersConstructorProps) => {
  const intl = useIntl();
  const { darkMode } = useContext(ThemeContext);

  const [filters, dispatchFilters] = useReducer(
    filtersReducer,
    initialFiltersState
  );

  useEffect(() => {
    if (data) {
      dispatchFilters({
        type: "loadFilters",
        payload: data,
      });
    }
  }, [data]);

  useEffect(
    () => setParsedData(JSON.stringify(filters), "filters"),
    [filters, setParsedData]
  );

  const iconColor = darkMode ? "" : colors.darkBlue;

  if (!dataSourceId) {
    return <ErrorMessage title="dataSourceIdMissing" />;
  }
  if (isManualTextControl) {
    return (
      <CSSQueryConstructorContainer>
        <Typography variant="h6">{intl.messages["filters"]}</Typography>
        <CSSQueryConstructorRow>
          <CSSQueryConstructorRowLabel>
            {intl.messages["filters"]}
          </CSSQueryConstructorRowLabel>
          {filters?.map((exp, index) => (
            <Filter
              dataSourceId={dataSourceId}
              key={index}
              data={exp}
              onSaveChanges={(payload, initialObj) =>
                dispatchFilters({
                  type: "changeFilter",
                  payload,
                  initialObj,
                })
              }
              deleteHandler={(payload) =>
                dispatchFilters({ type: "deleteFilter", payload })
              }
              isManualTextControl={isManualTextControl}
            />
          ))}
          <DbaIconButton
            size="small"
            icon={<AddIcon sx={{ color: iconColor }} fontSize="large" />}
            onClick={() => dispatchFilters({ type: "addFilter" })}
          />
        </CSSQueryConstructorRow>
      </CSSQueryConstructorContainer>
    );
  }
  return (
    <CSSQueryConstructorContainer>
      <Typography variant="h6">{intl.messages["filtersSettings"]}</Typography>
      {!tableName && (
        <Alert severity="info">
          {intl.messages["filtersConstructorInfoMessage"]}
        </Alert>
      )}
      <CSSQueryConstructorRow>
        <CSSQueryConstructorRowLabel>
          {intl.messages["filters"]}
        </CSSQueryConstructorRowLabel>
        {tableName &&
          filters?.map((exp, index) => (
            <Filter
              dataSourceId={dataSourceId}
              tableName={tableName}
              key={index}
              data={exp}
              onSaveChanges={(payload, initialObj) =>
                dispatchFilters({
                  type: "changeFilter",
                  payload,
                  initialObj,
                })
              }
              deleteHandler={(payload) =>
                dispatchFilters({ type: "deleteFilter", payload })
              }
            />
          ))}
        <DbaIconButton
          disabled={!tableName}
          size="small"
          icon={<AddIcon sx={{ color: iconColor }} fontSize="large" />}
          onClick={() => dispatchFilters({ type: "addFilter" })}
        />
      </CSSQueryConstructorRow>
    </CSSQueryConstructorContainer>
  );
};
