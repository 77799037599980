import styled from "@emotion/styled";

export const CSSImage = styled.div`
  width: 100%;
  height: 100%;

  img {
    width: 100%;
    height: 100%;
    padding: 10px;
    margin: 10px;
    object-fit: contain;
  }
`;
