import React, { useEffect, useState, useCallback, useRef } from "react";
import { GeoJSON } from "react-leaflet";
import { Geometry, Feature } from "geojson";
import { useLazyGetMapObjectDataQuery } from "../../../../../features/serviceSlices/serviceHooks";
import L from "leaflet";
import { LoadingIndicator } from "./LoadingIndicator";
import { DbaSnackbar } from "../../../../../DbaComponents";
import { useAppSelector } from "../../../../../utils/reduxHooks";
import { MapObjectInfoModal } from "./ModalComponents/MapObjectInfoModal";
import { createMarkerHTML } from "../MarkersStyles/MarkersStyles";
import {
  hexToRgb,
  createRGBString,
} from "../../../../../utils/helpers/functions";
import "../MarkersStyles/MarkersStyles.scss";
import { getBoundBoxFromFeatureCollection } from "../BoundsFit/helpers";
import {
  TFeatureCollectionGeoObject,
  TBoundBoxWidthId,
} from "../../../../../features/serviceSlices/MapObjects/Types";

const COLOR_IF_NOT_SET = "#a0a8e4";
const OPACITY_IF_NOT_SET = 50;
const BORDER_SIZE_IF_NOT_SET = 1;
const POPUP_DISPLAY_TRESHOLD = 5;
const VERTICAL_POPUP_OFFSET = -5;
const TOOLTIP_MAX_WIDTH = 150;

type TMapLayer = {
  id: string;
  setFilterValue: (arg: string) => void;
  filterKey: string | null;
  onLayerLoad?: (data: { boundBox: TBoundBoxWidthId }) => void;
  filters: any[];
  dashboardId?: string;
  isShowTooltips: boolean;
};

export const MapLayer: React.FC<TMapLayer> = ({
  id,
  setFilterValue,
  filterKey,
  onLayerLoad,
  filters,
  dashboardId,
  isShowTooltips,
}) => {
  const { type, startDate, endDate, timezone } = useAppSelector(
    (state) => state.app
  );
  const [getMapObjectData, mapDataResponse] = useLazyGetMapObjectDataQuery();

  const [open, setOpen] = useState(false);
  const [featureObjectID, setFeatureObjectID] = useState(null);
  const pCount = useRef<number | null>(null);

  const visualSettingsForAllFeature = mapDataResponse.data?.visualSettings;
  const colorForAllFeatures =
    visualSettingsForAllFeature?.color ?? COLOR_IF_NOT_SET;

  useEffect(() => {
    setTimeout(() => {
      if (type !== "NotSet" && type) {
        getMapObjectData({
          mapObjectID: id,
          dashboardID: dashboardId!,
          startDate,
          endDate,
          periodType: type,
          filters,
        });
      }
    }, 0);
  }, [dashboardId, endDate, filters, getMapObjectData, id, startDate, type]);

  useEffect(() => {
    if (mapDataResponse.isSuccess && onLayerLoad) {
      let dataUnknown = mapDataResponse.data.data[0];
      let data: TFeatureCollectionGeoObject = Array.isArray(dataUnknown.data)
        ? dataUnknown.data[0]
        : dataUnknown.data;
      // всегда будет "FeatureCollection" с бэка
      if (data.type !== "FeatureCollection") {
        return;
      }
      onLayerLoad({
        boundBox: { ...getBoundBoxFromFeatureCollection(data), layerID: id },
      });
    }
  }, [id, mapDataResponse.data?.data, mapDataResponse.isSuccess, onLayerLoad]);

  useEffect(() => {
    if (featureObjectID) {
      setOpen(true);
    }
  }, [featureObjectID, setOpen]);

  useEffect(() => {
    if (!open) {
      setFeatureObjectID(null);
    }
  }, [open]);

  const createGeoJSONMarker = useCallback(
    (feature, latlng) => {
      const marker =
        (visualSettingsForAllFeature?.marker as string) ?? "default";
      const color = visualSettingsForAllFeature?.featureCustomColor
        ? feature.properties[visualSettingsForAllFeature.colorField] ??
          COLOR_IF_NOT_SET
        : colorForAllFeatures;
      const size = (visualSettingsForAllFeature?.size as number) ?? 1;
      const CustomIcon = L.divIcon({
        html: createMarkerHTML({ marker, color, size }) as HTMLElement,
        iconSize: [10, 10], //Оставим размер для старых дефолтных иконок
        className: "leaflet-hide-marker",
      });
      return L.marker(latlng, { icon: CustomIcon });
    },
    [visualSettingsForAllFeature, colorForAllFeatures]
  );

  const createGeoJSONLinesAndPolygonsStyle = useCallback(
    (feature) => {
      const isFilter = mapDataResponse?.data?.actionType === "Filter";
      const isInteractive = mapDataResponse?.data?.actionType === "Information";

      const color = visualSettingsForAllFeature?.featureCustomColor
        ? feature.properties[visualSettingsForAllFeature.colorField] ??
          COLOR_IF_NOT_SET
        : colorForAllFeatures;

      const style: Record<string, string | number | boolean> = {
        interactive: isInteractive || isFilter,
        color,
        fillColor: createRGBString(hexToRgb(color)!),
        weight:
          visualSettingsForAllFeature?.borderSize ?? BORDER_SIZE_IF_NOT_SET,
      };

      if (feature.geometry.type === "Polygon") {
        const opacity =
          ((visualSettingsForAllFeature?.opacity as number) ??
            OPACITY_IF_NOT_SET) / 100;
        style.fillColor = createRGBString({ ...hexToRgb(color)! });
        style.fillOpacity = opacity;
      }

      return style;
    },
    [
      colorForAllFeatures,
      mapDataResponse?.data?.actionType,
      visualSettingsForAllFeature?.borderSize,
      visualSettingsForAllFeature?.colorField,
      visualSettingsForAllFeature?.featureCustomColor,
      visualSettingsForAllFeature?.opacity,
    ]
  );

  const clickToFeature = useCallback(
    (e) => {
      if (
        mapDataResponse.isSuccess &&
        mapDataResponse.data.actionType === "Filter" &&
        filterKey &&
        e.sourceTarget.feature.properties[filterKey]
      ) {
        setFilterValue(e.sourceTarget.feature.properties[filterKey]);
        return;
      }
      if (mapDataResponse?.data?.actionType === "Information") {
        setFeatureObjectID(e.sourceTarget.feature.properties.id);
      }
    },
    [
      filterKey,
      mapDataResponse?.data?.actionType,
      mapDataResponse.isSuccess,
      setFilterValue,
    ]
  );

  const bindPopupOrTooltipToLayer = useCallback(
    (feature: Feature<Geometry, any>, layer: L.Layer) => {
      const isShowPermanentPopups: boolean =
        isShowTooltips &&
        feature.geometry.type === "Point" &&
        pCount.current !== null &&
        pCount.current <= POPUP_DISPLAY_TRESHOLD;
      if (isShowPermanentPopups) {
        layer.bindPopup(() => feature.properties.name || null, {
          autoClose: false,
          closeOnClick: false,
          maxWidth: TOOLTIP_MAX_WIDTH,
          offset: [0, VERTICAL_POPUP_OFFSET],
        });
        layer.on("add", (e) => {
          e.target.openPopup();
        });
        //чтобы при клике на маркер (вызов модалки) не исчезал popup
        layer.on("click", (e) => {
          e.target.togglePopup();
        });
      } else {
        layer.bindTooltip(() => feature.properties.name || null, {
          permanent: false,
          direction: "center",
          className: "my-labels",
          offset: [0, VERTICAL_POPUP_OFFSET],
        });
      }
    },
    [isShowTooltips]
  );

  const onEachFeatureHandler = useCallback(
    (feature: Feature<Geometry, any>, layer: L.Layer) => {
      layer.on("click", (e: unknown) => {
        clickToFeature(e);
      });
      bindPopupOrTooltipToLayer(feature, layer);
    },
    [bindPopupOrTooltipToLayer, clickToFeature]
  );

  const countNumberOfPointsInGeoJSON = () => {
    if (mapDataResponse.data) {
      const featureCollection = mapDataResponse.data.data[0]
        .data as TFeatureCollectionGeoObject;
      if (featureCollection?.features) {
        const points = featureCollection.features.filter(
          (x) => x.geometry.type === "Point"
        ).length;
        pCount.current = points;
      }
    }
  };

  if (mapDataResponse.isLoading || mapDataResponse.isFetching) {
    return <LoadingIndicator />;
  }

  if (mapDataResponse.isError) {
    return <DbaSnackbar error={true} errorMessage="MapObjectsLoadingError" />;
  }

  if (mapDataResponse.isSuccess) {
    countNumberOfPointsInGeoJSON();
    return (
      <>
        {mapDataResponse.data.data.map((item) => {
          return (
            <div key={item.id}>
              <GeoJSON
                style={createGeoJSONLinesAndPolygonsStyle}
                data={item.data as TFeatureCollectionGeoObject}
                pointToLayer={createGeoJSONMarker}
                onEachFeature={onEachFeatureHandler}
              />
              <MapObjectInfoModal
                open={open}
                setOpen={setOpen}
                isModalVertical={mapDataResponse.data.isModalVertical}
                appTimezone={timezone.id}
                dashboardId={dashboardId}
                mapObjectId={id}
                featureObjectId={featureObjectID}
                filters={filters}
              />
            </div>
          );
        })}
      </>
    );
  }
  return null;
};
