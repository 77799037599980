import { useState, useContext, useReducer, useEffect } from "react";
import { DbaDataFetchSelect, DbaIconButton } from "../../DbaComponents";
import AddIcon from "@mui/icons-material/Add";
import colors from "../../Variables.module.scss";
import { ThemeContext } from "../../utils/ThemeContext";
import { Expression } from "./components/Expression";
import {
  ExpressionsType,
  GroupType,
  OrdersType,
  OrderType,
  SelectsType,
  MacrosType,
  MacrosActionType,
  MacrosesType,
  SelectType,
  ExpressionActionType,
  GroupActionType,
  OrderActionType,
  SelectActionType,
  QueryConstructorProps,
  EAliasesDefaults,
} from "./QueryConstructor.types";
import { Group } from "./components/Group";
import {
  CSSQueryConstructorContainer,
  CSSQueryConstructorTitle,
  CSSQueryConstructorRow,
  CSSQueryConstructorRowLabel,
  CSSQueryConstructorAddButton,
} from "./QueryConstructor.styles";
import { Order } from "./components/Order";
import { AddButton, ErrorMessage } from "../common";
import { useIntl } from "react-intl";
import { Select } from "./components/Select/Select";
import Alert from "@mui/material/Alert";
import { MenuButton } from "./components/MenuButton";
import { Macros } from "./components/Macros";
import { v4 as uuidv4 } from "uuid";
import { InfoOutlined } from "@mui/icons-material";
import { Tooltip } from "@mui/material";

const expressionReducer = (
  state: ExpressionsType | null,
  action: ExpressionActionType
) => {
  switch (action.type) {
    case "addExpression":
      const newExpession = { field: "", condition: "", values: "" };
      if (state) {
        const newExpressionArr = [...state];
        newExpressionArr.push(newExpession);
        return newExpressionArr;
      }
      return [newExpession];
    case "changeExpression":
      if (state) {
        const idx = state.findIndex((i) => i === action.initialObj);
        const newExpressionArr = [...state];
        newExpressionArr.splice(idx, 1, action.payload);
        return newExpressionArr;
      }
      return state;
    case "deleteExpression":
      if (state) {
        return state.filter((i) => i !== action.payload);
      }
      return state;
    case "loadExpressions":
      return action.payload;
    default:
      return state;
  }
};

const groupReducer = (state: GroupType | null, action: GroupActionType) => {
  switch (action.type) {
    case "addGroup":
      const newGroup = "";
      if (state) {
        const newExpressionArr = [...state];
        newExpressionArr.push(newGroup);
        return newExpressionArr;
      }
      return [newGroup];
    case "changeGroup":
      if (state) {
        const newExpressionArr = [...state];
        newExpressionArr.splice(action.idx, 1, action.payload);
        return newExpressionArr;
      }
      return state;
    case "deleteGroup":
      if (state) {
        return state.filter((i) => i !== action.payload);
      }
      return state;

    case "loadGroup":
      return action.payload;
    default:
      return state;
  }
};

const selectReducer = (state: SelectsType | null, action: SelectActionType) => {
  switch (action.type) {
    case "addSelect":
      const getInitialAliasState = () => {
        if (action.widgetType === "Filter") {
          return state && state.length > 0
            ? EAliasesDefaults.label
            : EAliasesDefaults.key;
        }
        return EAliasesDefaults.value;
      };
      const newSelect: SelectType = {
        alias: getInitialAliasState(),
        isExtended: false,
        parts: [
          {
            column: null,
            function: null,
            type: "Field",
            operand: null,
            id: uuidv4(),
          },
        ],
      };
      if (state) {
        const newSelectArr = [...state];
        newSelectArr.push(newSelect);
        return newSelectArr;
      }
      return [newSelect];
    case "changeSelect":
      if (state) {
        const idx = state.findIndex((i) => i === action.initialObj);
        const newSelectArr = [...state];
        newSelectArr.splice(idx, 1, action.payload);
        return newSelectArr;
      }
      return state;
    case "deleteSelect":
      if (state) {
        return state.filter((i) => i !== action.payload);
      }
      return state;
    case "loadSelect":
      return action.payload;
    default:
      return state;
  }
};

const orderReducer = (state: OrdersType | null, action: OrderActionType) => {
  switch (action.type) {
    case "addOrder":
      const newOrder: OrderType = { column: "", sort: "asc" };
      if (state) {
        const newOrderArr = [...state];
        newOrderArr.push(newOrder);
        return newOrderArr;
      }
      return [newOrder];
    case "changeOrder":
      if (state) {
        const idx = state.findIndex((i) => i === action.initialObj);
        const newOrderArr = [...state];
        newOrderArr.splice(idx, 1, action.payload);
        return newOrderArr;
      }
      return state;
    case "deleteOrder":
      if (state) {
        return state.filter((i) => i !== action.payload);
      }
      return state;
    case "loadOrder":
      return action.payload;
    default:
      return state;
  }
};

const macrosesReducer = (
  state: MacrosesType | null,
  action: MacrosActionType
) => {
  switch (action.type) {
    case "addMacros":
      const newMacros: MacrosType = { macrosType: "" };
      if (state) {
        const newMacrosArr = [...state];
        newMacrosArr.push(newMacros);
        return newMacrosArr;
      }
      return [newMacros];
    case "changeMacros":
      if (state) {
        const idx = state.findIndex((i) => i === action.initialObj);
        const newMacrosArr = [...state];
        newMacrosArr.splice(idx, 1, action.payload);
        return newMacrosArr;
      }
      return state;
    case "deleteMacros":
      if (state) {
        return state.filter((i) => i !== action.payload);
      }
      return state;
    case "loadMacroses":
      return action.payload;
    default:
      return state;
  }
};

const initialExpessionState: ExpressionsType | null = null;
const initialGroupState: GroupType | null = null;
const initialOrderState: OrdersType | null = null;
const initialSelectState: SelectsType | null = null;
const initialMacrosesState: MacrosesType | null = null;

export const QueryConstructor = ({
  getQuery,
  getQueryResponse,
  data,
  widgetType,
  dataSourceId,
  queryCommand,
  setParsedData,
}: QueryConstructorProps) => {
  const intl = useIntl();
  const { darkMode } = useContext(ThemeContext);
  const [tableName, setTableName] = useState<null | { table: string }>(null);
  const [time, setTime] = useState<any>(null);

  const [expression, dispatchExpression] = useReducer(
    expressionReducer,
    initialExpessionState
  );
  const [select, dispatchSelect] = useReducer(
    selectReducer,
    initialSelectState
  );
  const [group, dispatchGroup] = useReducer(groupReducer, initialGroupState);
  const [order, dispatchOrder] = useReducer(orderReducer, initialOrderState);
  const [macroses, dispatchMacroses] = useReducer(
    macrosesReducer,
    initialMacrosesState
  );

  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  useEffect(() => {
    if (data) {
      setTableName({ table: data.table });
      data.time && setTime({ name: data.time });
      dispatchSelect({
        type: "loadSelect",
        payload: data.select,
      });
      data.where?.expression &&
        dispatchExpression({
          type: "loadExpressions",
          payload: data.where.expression,
        });
      data.where?.macroses &&
        dispatchMacroses({
          type: "loadMacroses",
          payload: data.where.macroses,
        });
      data.group &&
        dispatchGroup({
          type: "loadGroup",
          payload: data.group,
        });
      data.order &&
        dispatchOrder({
          type: "loadOrder",
          payload: data.order,
        });
    }
  }, [data]);

  const errorCondition = !tableName?.table || !select || select?.length === 0;

  const isErrorState = () => {
    if (errorCondition) {
      return true;
    }
    switch (widgetType) {
      case "Filter":
        return select?.length < 2;
      default:
        return false;
    }
  };

  useEffect(() => {
    if (error) {
      if (!select || select.length === 0) {
        setErrorMessage("SELECT");
        return;
      }
      switch (widgetType) {
        case "Default":
          if (!time) {
            setErrorMessage("Time Column");
          }
          break;
        case "Filter":
          if (select && select?.length < 2) {
            setErrorMessage(
              intl.messages["selectErrorMessageForFilterWidget"] as string
            );
          }
          break;
        default:
          return setErrorMessage("SELECT");
      }
    }
  }, [error, intl.messages, select, time, widgetType]);

  const onSaveQueryHandler = () => {
    if (isErrorState()) {
      setError(true);
      return;
    }
    setError(false);
    setErrorMessage(null);
    const query = {
      table: tableName!.table,
      time: time?.name ? time.name : null,
      select,
      where: {
        expression,
        macroses: macroses
          ? macroses.filter((macros) => macros.macrosType !== "")
          : macroses,
      },
      group,
      order,
    };

    setParsedData(JSON.stringify(query), "queryobject");
    getQuery({ queryObject: JSON.stringify(query) });
  };

  const database = JSON.parse(queryCommand)?.database;
  const iconColor = darkMode ? "" : colors.darkBlue;

  const showAddSelectButton = () => {
    switch (widgetType) {
      case "Default":
        return (select && select.length <= 0) || !select;
      case "Filter":
        return (select && select.length <= 1) || !select;
      case "Diagram":
        return true;
      case "Table":
        return true;
      default:
        return !widgetType ? true : false;
    }
  };

  const getRowTooltip = (intlMessage: string) => (
    <Tooltip
      placement="right"
      arrow={true}
      title={intl.messages[intlMessage]}
      style={{
        marginLeft: "5px",
        marginRight: "2px",
        height: "25px",
        width: "25px",
      }}
    >
      <InfoOutlined sx={{ width: 15, height: 15, color: "#888888" }} />
    </Tooltip>
  );

  const tableRow = (
    <CSSQueryConstructorRow>
      <CSSQueryConstructorRowLabel>
        {intl.messages["table"] as string}
        {getRowTooltip("selectTooltip")}
      </CSSQueryConstructorRowLabel>
      {database ? (
        <DbaDataFetchSelect
          required
          error={error && (!tableName || tableName?.table === "")}
          size="small"
          url={`api/Query/GetTables?DataSourceID=${dataSourceId}&Database=${database}`}
          label="table"
          selectedValue={tableName}
          setSelectedValue={setTableName}
          optionLabel="table"
        />
      ) : (
        <Alert severity="error">{intl.messages["dataBaseNotSelected"]}</Alert>
      )}
    </CSSQueryConstructorRow>
  );

  const timeColumnRow = (
    <CSSQueryConstructorRow>
      {widgetType !== "Filter" && (
        <>
          <CSSQueryConstructorRowLabel>
            {intl.messages["linkToDateBy"]}
            {getRowTooltip("linkToDateTooltip")}
          </CSSQueryConstructorRowLabel>
          {tableName && (
            <DbaDataFetchSelect
              required={false}
              error={error && !time && widgetType === "Default"}
              size="small"
              url={`api/Query/GetFields?DataSourceID=${dataSourceId}&TableName=${tableName.table}`}
              label="column"
              selectedValue={time}
              setSelectedValue={setTime}
              disableClearable={false}
              showClearIcon={true}
            />
          )}
        </>
      )}
    </CSSQueryConstructorRow>
  );

  const selectRow = (
    <CSSQueryConstructorRow>
      <CSSQueryConstructorRowLabel>
        {intl.messages["columns"]}
        {getRowTooltip("columnTooltip")}
      </CSSQueryConstructorRowLabel>
      {tableName &&
        select?.map((obj, index) => (
          <Select
            widgetType={widgetType}
            dataSourceId={dataSourceId!}
            tableName={tableName?.table}
            key={index}
            data={obj}
            deleteHandler={(payload) =>
              dispatchSelect({ type: "deleteSelect", payload })
            }
            onSaveChanges={(payload, initialObj) =>
              dispatchSelect({
                type: "changeSelect",
                payload,
                initialObj,
              })
            }
          />
        ))}

      {showAddSelectButton() && (
        <CSSQueryConstructorAddButton>
          <DbaIconButton
            disabled={!tableName}
            size="small"
            icon={<AddIcon sx={{ color: iconColor }} fontSize="large" />}
            onClick={() => dispatchSelect({ type: "addSelect", widgetType })}
          />
        </CSSQueryConstructorAddButton>
      )}
    </CSSQueryConstructorRow>
  );

  const whereRow = (
    <CSSQueryConstructorRow>
      <CSSQueryConstructorRowLabel>
        {intl.messages["whereBy"]}
        {getRowTooltip("whereByTooltip")}
      </CSSQueryConstructorRowLabel>
      {macroses &&
        macroses.map((macros, index) => (
          <Macros
            key={index}
            data={macros}
            onSaveChanges={(payload, initialObj) =>
              dispatchMacroses({
                type: "changeMacros",
                payload,
                initialObj,
              })
            }
            deleteHandler={(payload) =>
              dispatchMacroses({ type: "deleteMacros", payload })
            }
          />
        ))}
      {tableName &&
        expression?.map((exp, index) => (
          <Expression
            dataSourceId={dataSourceId!}
            tableName={tableName?.table}
            key={index}
            data={exp}
            onSaveChanges={(payload, initialObj) =>
              dispatchExpression({
                type: "changeExpression",
                payload,
                initialObj,
              })
            }
            deleteHandler={(payload) =>
              dispatchExpression({ type: "deleteExpression", payload })
            }
          />
        ))}
      <MenuButton
        disabled={!tableName}
        onAddExpressionHandler={() =>
          dispatchExpression({ type: "addExpression" })
        }
        onAddMacrosHandler={() => dispatchMacroses({ type: "addMacros" })}
      />
    </CSSQueryConstructorRow>
  );

  const groupByRow = (
    <CSSQueryConstructorRow>
      <CSSQueryConstructorRowLabel>
        {intl.messages["groupBy"] as string}
        {getRowTooltip("groupByTooltip")}
      </CSSQueryConstructorRowLabel>
      {tableName &&
        group?.map((exp, index) => (
          <Group
            dataSourceId={dataSourceId!}
            tableName={tableName?.table}
            key={index}
            data={exp}
            onSaveChanges={(payload) =>
              dispatchGroup({
                type: "changeGroup",
                payload,
                idx: index,
              })
            }
            deleteHandler={(payload) =>
              dispatchGroup({ type: "deleteGroup", payload })
            }
          />
        ))}
      <CSSQueryConstructorAddButton>
        <DbaIconButton
          disabled={!tableName}
          size="small"
          icon={<AddIcon sx={{ color: iconColor }} fontSize="large" />}
          onClick={() => dispatchGroup({ type: "addGroup" })}
        />
      </CSSQueryConstructorAddButton>
    </CSSQueryConstructorRow>
  );

  const orderBy = (
    <CSSQueryConstructorRow>
      <CSSQueryConstructorRowLabel>
        {intl.messages["sortBy"]}
        {getRowTooltip("sortByTooltip")}
      </CSSQueryConstructorRowLabel>
      {tableName &&
        order?.map((obj, index) => (
          <Order
            dataSourceId={dataSourceId!}
            tableName={tableName?.table}
            key={index}
            data={obj}
            deleteHandler={(payload) =>
              dispatchOrder({ type: "deleteOrder", payload })
            }
            onSaveChanges={(payload, initialObj) =>
              dispatchOrder({
                type: "changeOrder",
                payload,
                initialObj,
              })
            }
          />
        ))}
      <CSSQueryConstructorAddButton>
        <DbaIconButton
          disabled={!tableName}
          size="small"
          icon={<AddIcon sx={{ color: iconColor }} fontSize="large" />}
          onClick={() => dispatchOrder({ type: "addOrder" })}
        />
      </CSSQueryConstructorAddButton>
    </CSSQueryConstructorRow>
  );
  if (!dataSourceId) {
    return <ErrorMessage title="dataSourceIdMissing" />;
  }

  return (
    <CSSQueryConstructorContainer>
      <CSSQueryConstructorTitle>
        {intl.messages["request"]}
      </CSSQueryConstructorTitle>
      {tableRow}
      {timeColumnRow}
      {selectRow}
      {whereRow}
      {groupByRow}
      {orderBy}
      {error && isErrorState() && (
        <Alert severity="error">
          {intl.messages["missingField"]}:{errorMessage}
        </Alert>
      )}
      <AddButton
        text="apply"
        showIcon={false}
        status={getQueryResponse.status}
        onClick={onSaveQueryHandler}
      />
    </CSSQueryConstructorContainer>
  );
};
