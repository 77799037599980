import { TextWidgetProps } from "./TextWidget.types";
import { WidgetLayout } from "..";

export const TextWidget = ({
  editMode,
  data,
  selectedElement,
  setSelectedElement,
}: TextWidgetProps) => {
  return (
    <WidgetLayout
      settings={data.widgetSettings?.settings.widget!}
      label={data.label}
      data={data}
      type="Text"
      editMode={editMode}
      setSelectedElement={setSelectedElement}
      selectedElement={selectedElement}
      settingsId={data.widgetSettings?.id}
      dashboardId={data.widgetSettings?.dashboardID}
      widgetID={data.widgetSettings?.widgetID}
    >
      {null}
    </WidgetLayout>
  );
};
