import { useState, useReducer, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { DbaTextField, DbaButton } from "../../DbaComponents";
import {
  useGetHeadersDescriptionQuery,
  useCreateReferenceHeaderMutation,
  useLazyGetReferenceHeaderQuery,
  useUpdateReferenceHeaderMutation,
} from "../../features/serviceSlices/serviceHooks";
import {
  ErrorMessage,
  LoadingScreen,
  SaveButton,
} from "../../components/common";
import AddIcon from "@mui/icons-material/Add";
import CancelIcon from "@mui/icons-material/Cancel";
import { ReferenceChip } from "./ReferenceInputGenerator/ReferenceChip";
import Alert from "@mui/material/Alert";
import {
  CSSHeadersContainer,
  CSSButtonContainer,
  CSSReferenceBox,
} from "./Reference.styles";
import { useIntl } from "react-intl";
import { DbaConfirmDialog } from "../../DbaComponents/DbaConfirmDialog";

const referenceHeadersReducer = (state: any, action: any) => {
  switch (action.type) {
    case "addReferenceHeader":
      const newReferenceHeader = {};
      if (state) {
        const newOrderArr = [...state];
        newOrderArr.push(newReferenceHeader);
        return newOrderArr;
      }
      return [newReferenceHeader];
    case "changeReferenceHeader":
      if (state) {
        const idx = state.findIndex((i: any) => i === action.initialObj);
        const newSelectArr = [...state];
        newSelectArr.splice(idx, 1, action.payload);
        return newSelectArr;
      }
      return state;
    case "deleteReferenceHeader":
      if (state) {
        return state.filter((i: any) => i !== action.payload);
      }
      return state;
    case "loadReferenceHeader":
      return action.payload;
    default:
      return state;
  }
};

const initialReferenceHeadersState: any = [];

export const Reference = () => {
  const intl = useIntl();
  const [getHeader, getHeaderResponse] = useLazyGetReferenceHeaderQuery();
  const params = useParams<Record<string, string | undefined>>();
  const editMode = Object.keys(params).length > 0;
  const [createReferenceHeader, createReferenceHeaderResponse] =
    useCreateReferenceHeaderMutation();
  const [updateReferenceHeader, updateReferenceHeaderResponse] =
    useUpdateReferenceHeaderMutation();
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [error, setError] = useState(false);
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] =
    useState<boolean>(false);

  const [referenceHeaders, dispatchReferenceHeader] = useReducer(
    referenceHeadersReducer,
    initialReferenceHeadersState
  );
  const headerDescription = useGetHeadersDescriptionQuery();

  const onSaveHandler = () => {
    if (
      editMode
        ? updateReferenceHeaderResponse.status === "pending" ||
          updateReferenceHeaderResponse.status === "fulfilled"
        : createReferenceHeaderResponse.status === "pending" ||
          createReferenceHeaderResponse.status === "fulfilled"
    )
      return;
    if (!name.trim() || referenceHeaders.length === 0) {
      setError(true);
      return;
    }
    setError(false);

    const objectToSave = {
      name: name.trim(),
      headers: referenceHeaders,
    };
    editMode
      ? updateReferenceHeader({ ...objectToSave, id: params.id! })
      : createReferenceHeader(objectToSave);
  };

  const onConfirmCancelClick = () => {
    navigate(-1);
  };

  const onAddReferenceHeaderHandler = () => {
    dispatchReferenceHeader({ type: "addReferenceHeader" });
  };

  useEffect(() => {
    if (editMode && params.id) {
      getHeader(params.id);
    }
  }, [editMode, getHeader, params]);

  useEffect(() => {
    if (getHeaderResponse.data) {
      setName(getHeaderResponse.data.name);
      dispatchReferenceHeader({
        type: "loadReferenceHeader",
        payload: getHeaderResponse.data.headers,
      });
    }
  }, [getHeaderResponse]);

  const confirmCancelModal = (
    <DbaConfirmDialog
      isOpen={isConfirmDialogOpen}
      title="cancelConfirmTitle"
      message="cancelConfirmMessage"
      onAccept={onConfirmCancelClick}
      onDecline={() => setIsConfirmDialogOpen(false)}
    />
  );

  if (
    (editMode && getHeaderResponse.isLoading) ||
    headerDescription.isLoading
  ) {
    return <LoadingScreen />;
  }

  if ((editMode && getHeaderResponse.isError) || headerDescription.isError) {
    return <ErrorMessage />;
  }

  return headerDescription.isSuccess &&
    (editMode ? getHeaderResponse.isSuccess : true) ? (
    <CSSReferenceBox>
      <DbaTextField
        required
        error={error && !name.trim()}
        size="small"
        label="label"
        value={name}
        setValue={setName}
        helperText="fieldIsEmptyError"
      />
      <CSSHeadersContainer>
        <DbaButton
          endIcon={<AddIcon />}
          text="addHeaders"
          onClick={onAddReferenceHeaderHandler}
        />
        <CSSHeadersContainer>
          {referenceHeaders.map((refHeader: any) => {
            return (
              <ReferenceChip
                key={refHeader.field}
                fields={headerDescription.data}
                data={refHeader}
                onDeleteHandler={() =>
                  dispatchReferenceHeader({
                    type: "deleteReferenceHeader",
                    payload: refHeader,
                  })
                }
                onSaveChangesHandler={(payload, initialObject) => {
                  dispatchReferenceHeader({
                    type: "changeReferenceHeader",
                    payload,
                    initialObj: refHeader,
                  });
                }}
              />
            );
          })}
        </CSSHeadersContainer>
      </CSSHeadersContainer>
      {error && referenceHeaders.length === 0 && (
        <Alert severity="error">{intl.messages["noReferenceHeaders"]}</Alert>
      )}
      <CSSButtonContainer>
        <DbaButton
          startIcon={<CancelIcon />}
          color="error"
          text="cancel"
          onClick={() => setIsConfirmDialogOpen(true)}
        />
        <SaveButton
          redirectUrl="/references"
          status={
            editMode
              ? updateReferenceHeaderResponse.status
              : createReferenceHeaderResponse.status
          }
          onClick={onSaveHandler}
        />
      </CSSButtonContainer>
      {confirmCancelModal}
    </CSSReferenceBox>
  ) : null;
};
