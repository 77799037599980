import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { useContext } from "react";
import { ThemeContext } from "../../../../utils/ThemeContext";
import colors from "../../../../Variables.module.scss";
import { useIntl } from "react-intl";
import { WidgetConstructorToolbarProps } from "./Types";
import {
  CSSSettingsToolbar,
  CSSToolbarContainer,
  CSSSettingContainer,
  CSSSliderContainer,
} from "./StyledComponents";
import Fade from "@mui/material/Fade";
import {
  DbaCheckbox,
  DbaSimpleSelect,
  DbaDataFetchSelect,
  DbaButton,
  DbaIconButton,
  DbaColorInput,
  DbaRadioGroup,
} from "../../../../DbaComponents";
import Divider from "@mui/material/Divider";
import FormatAlignLeftIcon from "@mui/icons-material/FormatAlignLeft";
import FormatAlignRightIcon from "@mui/icons-material/FormatAlignRight";
import FormatAlignCenterIcon from "@mui/icons-material/FormatAlignCenter";
import Slider from "@mui/material/Slider";
import { DbaToggleButtonGroup } from "./DbaToggleButtonGroup";
import { SaveTemplateModal } from "./SaveTemplateModal/SaveTemplateModal";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ClearIcon from "@mui/icons-material/Clear";
import { ColorSchemeType } from "../../../../features/serviceSlices/WidgetSettings/Types";
import AddIcon from "@mui/icons-material/Add";

const colorSchemeOptions: {
  id: ColorSchemeType;
  name: string;
}[] = [
  {
    id: "thresholds",
    name: "Пороги",
  },
  {
    id: "userColor",
    name: "Цвет",
  },
];

export const WidgetConstructorToolbar = ({
  selectedElement,
  setSelectedElement,
  settings,
  setSettings,
  widgetType,
}: WidgetConstructorToolbarProps) => {
  const intl = useIntl();
  const { darkMode } = useContext(ThemeContext);
  const defaultColor = darkMode ? colors.light : colors.darkBlue;

  return (
    <CSSToolbarContainer>
      <DbaToggleButtonGroup
        selectedElement={selectedElement}
        setSelectedElement={setSelectedElement}
        widgetType={widgetType}
        diagramType={settings.diagram?.diagramType}
      />

      <Fade in={Boolean(selectedElement)}>
        {selectedElement === "legends" ||
        selectedElement === "baseSettings" ||
        selectedElement === "interactivity" ||
        selectedElement === "arcLinkLabels" ||
        selectedElement === "labels" ||
        selectedElement === "styles" ||
        selectedElement === "axes" ||
        selectedElement === "points" ||
        selectedElement === "thresholds" ? (
          <div style={{ opacity: 0, height: "3.5rem" }}></div>
        ) : (
          <CSSSettingsToolbar>
            {selectedElement === "template" ? (
              <>
                <CSSSettingContainer>
                  <SaveTemplateModal
                    widgetType={widgetType}
                    setSettings={setSettings}
                    settings={settings}
                  />
                </CSSSettingContainer>
              </>
            ) : selectedElement === "body" ? (
              <>
                <CSSSettingContainer>
                  <DbaCheckbox
                    checked={
                      settings?.widget?.body?.visible === undefined
                        ? false
                        : !settings.widget.body?.visible
                    }
                    setChecked={(value) => {
                      setSettings((prevState: any) => ({
                        ...prevState,
                        widget: {
                          ...prevState.widget,
                          body: {
                            ...prevState.widget.body,
                            visible: !value,
                          },
                        },
                      }));
                    }}
                    label="hide"
                  />
                </CSSSettingContainer>
                <Divider orientation="vertical" variant="middle" flexItem />

                <CSSSettingContainer>
                  <DbaColorInput
                    value={settings?.widget?.body?.backgroundColor ?? ""}
                    onChange={(event) =>
                      setSettings((prevState: any) => ({
                        ...prevState,
                        widget: {
                          ...prevState.widget,
                          body: {
                            ...prevState.widget.body,
                            backgroundColor: event.target.value,
                          },
                        },
                      }))
                    }
                  />
                </CSSSettingContainer>
                <Divider orientation="vertical" variant="middle" flexItem />

                <CSSSliderContainer>
                  <p>{intl.messages["roundingOff"]}</p>
                  <Slider
                    valueLabelDisplay="auto"
                    min={0}
                    max={50}
                    value={settings?.widget?.body?.borderRadius ?? 8}
                    onChange={(e, newValue) =>
                      setSettings((prevState: any) => ({
                        ...prevState,
                        widget: {
                          ...prevState.widget,
                          body: {
                            ...prevState.widget.body,
                            borderRadius: newValue,
                          },
                        },
                      }))
                    }
                  />
                </CSSSliderContainer>
                <Divider orientation="vertical" variant="middle" flexItem />
                <CSSSliderContainer>
                  <p>{intl.messages["opacity"]}</p>
                  <Slider
                    valueLabelDisplay="auto"
                    min={0}
                    max={100}
                    value={
                      settings?.widget?.body?.backgroundOpacity === undefined
                        ? 100
                        : Math.round(
                            (settings.widget.body.backgroundOpacity as number) *
                              100
                          )
                    }
                    onChange={(e, newValue) => {
                      setSettings((prevState: any) => ({
                        ...prevState,
                        widget: {
                          ...prevState.widget,
                          body: {
                            ...prevState.widget.body,
                            backgroundOpacity: (newValue as number) / 100,
                          },
                        },
                      }));
                    }}
                  />
                </CSSSliderContainer>
                <Divider orientation="vertical" variant="middle" flexItem />
                <CSSSliderContainer>
                  <p>{intl.messages["blur"]}</p>
                  <Slider
                    valueLabelDisplay="auto"
                    min={0}
                    max={20}
                    value={
                      settings?.widget?.body?.blur
                        ? settings.widget.body.blur
                        : 0
                    }
                    onChange={(e, newValue) =>
                      setSettings((prevState: any) => ({
                        ...prevState,
                        widget: {
                          ...prevState.widget,
                          body: {
                            ...prevState.widget.body,
                            blur: newValue as number,
                          },
                        },
                      }))
                    }
                  />
                </CSSSliderContainer>
                <Divider orientation="vertical" variant="middle" flexItem />
                <CSSSettingContainer>
                  <DbaButton
                    onClick={() =>
                      setSettings((prevState: any) => ({
                        ...prevState,
                        widget: {
                          ...prevState.widget,
                          body: {
                            ...prevState.widget.body,
                            visible: true,
                            backgroundColor: undefined,
                            borderRadius: 8,
                            backgroundOpacity: 1,
                            blur: 0,
                          },
                        },
                      }))
                    }
                    text="reset"
                  />
                </CSSSettingContainer>
              </>
            ) : selectedElement === null ? (
              ""
            ) : selectedElement === "diagramType" ? (
              <CSSSettingContainer>
                <p>{intl.messages["diagramType"]}</p>
                <DbaDataFetchSelect
                  size="small"
                  label="diagramType"
                  selectedIndex={settings?.diagram?.diagramType}
                  setSelectedValue={(value: any) => {
                    setSettings((prevState: any) => ({
                      ...prevState,
                      diagram: {
                        ...prevState.diagram,
                        diagramType: value.id,
                      },
                    }));
                  }}
                  url="api/Types/Get?Name=DiagramType"
                />
              </CSSSettingContainer>
            ) : (
              <>
                <CSSSettingContainer>
                  <p>{intl.messages["fontSize"]}</p>
                  <DbaSimpleSelect
                    setSelectedValue={(value) => {
                      setSettings((prevState: any) => ({
                        ...prevState,
                        widget: {
                          ...prevState.widget,
                          [selectedElement]: {
                            ...prevState.widget[selectedElement],
                            fontSize: value,
                          },
                        },
                      }));
                    }}
                    selectedValue={settings.widget[selectedElement]?.fontSize}
                    options={[
                      8, 9, 10, 11, 12, 14, 16, 18, 20, 24, 26, 28, 32, 36, 48,
                      72,
                    ]}
                  />
                </CSSSettingContainer>
                <Divider orientation="vertical" variant="middle" flexItem />
                <CSSSettingContainer>
                  {widgetType !== "Text" && widgetType !== "Image" ? (
                    <>
                      {intl.messages["colorScheme"]}
                      <DbaRadioGroup
                        row
                        labels={colorSchemeOptions}
                        value={settings.widget?.colorScheme ?? "userColor"}
                        setValue={(value) =>
                          setSettings((prevState: any) => ({
                            ...prevState,
                            widget: {
                              ...prevState.widget,
                              colorScheme: value,
                            },
                          }))
                        }
                      />
                    </>
                  ) : (
                    <>{intl.messages["color"]}</>
                  )}

                  {settings.widget?.colorScheme === "userColor" && (
                    <>
                      <DbaColorInput
                        value={
                          settings?.widget?.fontColor
                            ? settings?.widget?.fontColor
                            : defaultColor
                        }
                        onChange={(event) =>
                          setSettings((prevState: any) => ({
                            ...prevState,
                            widget: {
                              ...prevState.widget,
                              fontColor: event.target.value,
                            },
                          }))
                        }
                      />
                      {settings?.widget?.gradientColor && (
                        <>
                          <DbaColorInput
                            value={
                              settings?.widget?.gradientColor
                                ? settings?.widget?.gradientColor
                                : defaultColor
                            }
                            onChange={(event) =>
                              setSettings((prevState: any) => ({
                                ...prevState,
                                widget: {
                                  ...prevState.widget,
                                  gradientColor: event.target.value,
                                },
                              }))
                            }
                          />
                        </>
                      )}
                      <div>
                        {!Boolean(settings?.widget?.gradientColor) && (
                          <DbaIconButton
                            icon={<AddIcon />}
                            onClick={() =>
                              setSettings((prevState: any) => ({
                                ...prevState,
                                widget: {
                                  ...prevState.widget,
                                  gradientColor: defaultColor,
                                },
                              }))
                            }
                          />
                        )}
                        <DbaIconButton
                          onClick={() =>
                            setSettings((prevState: any) => ({
                              ...prevState,
                              widget: {
                                ...prevState.widget,
                                fontColor: null,
                                gradientColor: null,
                              },
                            }))
                          }
                          icon={<ClearIcon />}
                        />
                      </div>
                    </>
                  )}
                </CSSSettingContainer>
                <Divider orientation="vertical" variant="middle" flexItem />
                <CSSSettingContainer>
                  <p>{intl.messages["fontWeight"]}</p>
                  <DbaSimpleSelect
                    setSelectedValue={(value) => {
                      setSettings((prevState: any) => ({
                        ...prevState,
                        widget: {
                          ...prevState.widget,
                          [selectedElement]: {
                            ...prevState.widget[selectedElement],
                            fontWeight: value,
                          },
                        },
                      }));
                    }}
                    selectedValue={settings.widget[selectedElement]?.fontWeight}
                    options={[100, 300, 400, 500, 700, 900]}
                  />
                </CSSSettingContainer>
                <Divider orientation="vertical" variant="middle" flexItem />
                <CSSSettingContainer>
                  <ToggleButtonGroup
                    exclusive
                    value={settings.widget[selectedElement]?.textAlign}
                    onChange={(e, value) =>
                      setSettings((prevState: any) => ({
                        ...prevState,
                        widget: {
                          ...prevState.widget,
                          [selectedElement]: {
                            ...prevState.widget[selectedElement],
                            textAlign: value,
                          },
                        },
                      }))
                    }
                    size="small"
                    sx={{
                      height: "fit-content",
                      background: darkMode ? colors.darkBlue : "",
                    }}
                  >
                    <ToggleButton
                      sx={{
                        border: `1px solid ${colors.purple500}`,
                      }}
                      value="left"
                      aria-label="left aligned"
                    >
                      <FormatAlignLeftIcon
                        fontSize="small"
                        sx={{ marginRight: ".25rem" }}
                      />
                    </ToggleButton>
                    <ToggleButton
                      sx={{
                        border: `1px solid ${colors.purple500}`,
                      }}
                      value="center"
                      aria-label="left aligned"
                    >
                      <FormatAlignCenterIcon
                        fontSize="small"
                        sx={{ marginRight: ".25rem" }}
                      />
                    </ToggleButton>
                    <ToggleButton
                      sx={{
                        border: `1px solid ${colors.purple500}`,
                      }}
                      value="right"
                      aria-label="left aligned"
                      // sx={{ textTransform: "none" }}
                    >
                      <FormatAlignRightIcon
                        fontSize="small"
                        sx={{ marginRight: ".25rem" }}
                      />
                    </ToggleButton>
                  </ToggleButtonGroup>
                </CSSSettingContainer>
                <Divider orientation="vertical" variant="middle" flexItem />
                {selectedElement === "difference" && (
                  <CSSSettingContainer>
                    <p>{intl.messages["positiveTrend"]}</p>
                    <DbaRadioGroup
                      row
                      labels={[
                        {
                          icon: <ArrowUpwardIcon fontSize="small" />,
                          label: "increase",
                        },
                        {
                          icon: <ArrowDownwardIcon fontSize="small" />,
                          label: "decrease",
                        },
                      ]}
                      value={settings?.widget?.difference?.positiveTrend ?? ""}
                      setValue={(value) => {
                        setSettings((prevState: any) => ({
                          ...prevState,
                          widget: {
                            ...prevState.widget,
                            difference: {
                              ...prevState.widget.difference,
                              positiveTrend: value,
                            },
                          },
                        }));
                      }}
                    />
                  </CSSSettingContainer>
                )}
                {widgetType !== "Text" ? (
                  <>
                    <Divider orientation="vertical" variant="middle" flexItem />
                    <CSSSettingContainer>
                      <DbaCheckbox
                        checked={!settings.widget[selectedElement]?.visible}
                        setChecked={(value) =>
                          setSettings((prevState: any) => ({
                            ...prevState,
                            widget: {
                              ...prevState.widget,
                              [selectedElement]: {
                                ...prevState.widget[selectedElement],
                                visible: !value,
                              },
                            },
                          }))
                        }
                        label="hide"
                      />
                    </CSSSettingContainer>
                  </>
                ) : null}
              </>
            )}
          </CSSSettingsToolbar>
        )}
      </Fade>
    </CSSToolbarContainer>
  );
};
