import React from "react";
import { ImageWidgetProps } from "./ImageWidget.types";
import { WidgetLayout } from "..";
import { DbaAlert } from "../../../../DbaComponents";
import { CSSImage } from "./ImageWidget.styles";

export const ImageWidget = ({
  editMode,
  data,
  selectedElement,
  setSelectedElement,
}: ImageWidgetProps) => {
  let content;

  if (!data.fileId) {
    content = <DbaAlert severity="warning" text="emptyData" />;
  } else {
    content = (
      <CSSImage>
        <img
          src={`${window.__env__.REACT_APP_BASE_URL}api/File/GetImage?ID=${data.fileId}`}
          alt="ImageWidget"
        />
      </CSSImage>
    );
  }

  return (
    <WidgetLayout
      settings={data.widgetSettings?.settings.widget!}
      label={data.label}
      data={data}
      type="Image"
      editMode={editMode}
      setSelectedElement={setSelectedElement}
      selectedElement={selectedElement}
      settingsId={data.widgetSettings?.id}
      dashboardId={data.widgetSettings?.dashboardID}
      widgetID={data.widgetSettings?.widgetID}
    >
      {content}
    </WidgetLayout>
  );
};
